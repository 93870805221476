import React from 'react';
import SvgIcon from '@talpanetwork/talpa-publishing-core/SvgIcon';

const overlay = (props) => (
  <SvgIcon {...props} width={212} height={465} viewBox="0 0 212 465">
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="#FFF"
      fillRule="evenodd"
      d="M0 0c20.924 5.054 34.802 13.11 45.235 26.166C56.07 39.729 68.165 62.52 77.992 89.579L211.926 465H0V0z"
    />
  </SvgIcon>
);

overlay.propTypes = SvgIcon.propTypes;

overlay.defaultProps = SvgIcon.defaultProps;

const SvgHeaderCurve = {
  overlay
};

export default SvgHeaderCurve;
