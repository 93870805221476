import { palette } from '../../palette/Palette';

const TileMessage = {
  messageBox: {
    backgroundColor: '#f6f6f6',
    color: palette.grey.second
  },
  title: {
    fontFamily: 'Sky-Bold',
    color: palette.primary.third
  },
  text: {
    color: palette.grey.third,
    fontFamily: 'Sky-Bold',
    fontSize: 15
  },
  overlay: {
    backgroundColor: palette.default.white
  }
};

export default TileMessage;
