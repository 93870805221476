import { palette } from '../palette/Palette';

const TextStyles = {
  articleTextSvg: {
    width: 40,
    height: 40
  },

  articleTextSvgSm: {
    width: 26,
    height: 26
  },

  textFrame: {
    padding: '35px 40px',
    backgroundColor: palette.grey.first
  },

  copyTextInner: {
    link: {
      color: palette.primary.first,
      '&:hover': {
        color: palette.primary.second
      }
    },
    subscript: {
      color: palette.grey.second
    },
    '& img': {
      objectFit: 'cover'
    }
  },
  callToAction: {
    marginBottom: 20
  },

  callToActionMd: {
    marginBottom: 17
  },

  callToActionSm: {
    marginBottom: 20
  }
};

export default TextStyles;
