import React from 'react';

const iconNavigationShape = (
  <g fillRule="evenodd">
    <g transform="translate(-1593.000000, 0.000000)">
      <g transform="translate(1593.000000, 0.000000)">
        <path d="M7,100 C2.52,84.1458333 0,67.3645833 0,50 C0,32.6354167 2.52,15.8541667 7,0 L7,100 Z" />
      </g>
    </g>
  </g>
);

export default iconNavigationShape;
