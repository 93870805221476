import { palette } from '@theme/palette/Palette';

const ArticleComposite = {
  compositeImageHolder: {
    paddingBottom: '56.25%'
  },

  compositeImageHolderSm: {
    width: '115%',
    left: '-7%'
  },

  compositeImageWrapperSm: {
    maxWidth: 768
  },

  title: {
    color: palette.primary.second
  }
};

export default ArticleComposite;
