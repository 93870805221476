import { palette } from '../palette/Palette';

const ListItems = {
  iconSize: {
    width: 16,
    height: 16
  },

  icon: {
    bottom: 0,
    right: 0
  },

  background: {},

  itemHolder: {},

  itemHolderArticle: {},

  listItemArticleSm: {
    paddingBottom: 8
  },

  textHolder: {
    color: palette.grey.second
  },

  itemMobileHolder: {
    backgroundColor: palette.default.white
  },

  masonryItemHolder: {
    backgroundColor: palette.default.white,

    '&:hover': {
      backgroundColor: palette.grey.first
    }
  },

  masonryTitleHover: {
    color: palette.primary.first
  },

  listImageMasonryMd: {
    width: '22%'
  },

  longListImageHolderMd: {
    width: '28%'
  },

  longLostTextHolderMasonryMd: {
    width: '72%'
  },

  masonryIconSize: {
    width: 16,
    height: 16
  },

  iconHolder: {
    height: 18
  },

  listItemArticle: {
    '&:hover': {
      '& span': {
        color: palette.primary.second
      },
      '& svg': {
        width: 48,
        height: 48
      }
    }
  }
};

export default ListItems;
