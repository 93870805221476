import { palette } from '../palette/Palette';

const AnswerList = {
  innerContainer: {
    padding: '0 68px'
  },
  innerContainerSm: {
    maxWidth: 300
  },
  song: {
    height: 80
  },

  dateHolder: {
    backgroundColor: palette.primary.first,
    minHeight: 40,
    margin: '0 0 10px'
  },

  timeHolder: {
    backgroundColor: palette.primary.second,
    minHeight: 40
  },

  arrowLeft: {
    left: 20
  },

  arrowRight: {
    right: 20
  },

  listHolder: {
    padding: '20px 0 0'
  },

  slideItemHolder: {
    height: 80,

    borderBottom: `1px solid ${palette.grey.first}`,

    '&:first-child': {
      borderTop: `1px solid ${palette.grey.first}`
    },

    '&:nth-child(even)': {
      backgroundColor: palette.grey.first
    }
  },

  slideItemHolderSecond: {
    '&:first-child': {
      backgroundColor: palette.grey.first
    },

    '&:nth-child(even)': {
      backgroundColor: palette.default.white
    }
  },

  slideItem: {
    width: 'calc(33.3% - 15px)',
    padding: '20px 20px 0 0'
  },

  slideItemLastChild: {
    padding: '20px 0 0 0'
  },

  guessIndexHolder: {
    height: 80,
    minWidth: 40,
    padding: '0 10px 0 0'
  },

  artistHolder: {
    padding: '0 0 0 10px'
  },

  correctGuesses: {
    backgroundColor: palette.primary.second,
    height: 80
  },

  listHolderMd: {
    padding: '20px 35px 0 35px'
  },

  listHolderSm: {
    padding: '29px 0 0'
  },

  slideItemHolderSm: {
    height: 100
  },

  guessIndexHolderSm: {
    height: 100,
    width: 80
  },

  songSm: {
    height: 100
  },

  timeHolderSm: {
    marginTop: 20
  },

  correctGuessesSm: {
    margin: '0 0 10px 0'
  }
};

export default AnswerList;
