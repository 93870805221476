import { palette } from '@theme/palette/Palette';

const PageNotFound = {
  smallGrid: {
    width: '800px'
  },
  padding: 100,
  paddingLg: 150,
  paddingMd: 120,
  paddingSm: 70,
  logo: {
    width: 234,
    height: 192
  },
  logoMd: {
    width: 185,
    height: 152
  },
  logoSm: {
    width: 171,
    height: 140
  },
  featuredItemsTitle: {
    color: palette.grey.third
  }
};

export default PageNotFound;
