import React from 'react';

const iconCtaArrow = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M51.8613 28.3771L36.5973 13.1131C35.673 12.2204 35.3024 10.8984 35.6277 9.65531C35.9531 8.41218 36.924 7.44136 38.1671 7.11597C39.4102 6.79058 40.7322 7.16127 41.6249 8.08556L62.9582 29.4189C64.3462 30.8073 64.3462 33.058 62.9582 34.4464L41.6249 55.7798C40.2298 57.1272 38.0121 57.108 36.6406 55.7365C35.2691 54.365 35.2499 52.1473 36.5973 50.7522L51.8613 35.4882H3.55556C1.59188 35.4882 0 33.8963 0 31.9327C0 29.969 1.59188 28.3771 3.55556 28.3771H51.8613Z"
  />
);

export default iconCtaArrow;
