import React from 'react';

const iconPlay = (
  <g fill="none" fillRule="evenodd">
    <circle cx="32" cy="32" r="32" />
    <circle fill="#007CDF" cx="32" cy="32" r="32" />
    <g fill="#FFF">
      <path d="M23.563 17v30l23.57-15z" />
    </g>
  </g>
);

export default iconPlay;
