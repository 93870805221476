export const palette = {
  primary: {
    first: '#007CDF',
    second: '#0943B9',
    third: '#00276F',
    fourth: '#006FC8'
  },
  secondary: {
    first: '#C9FF01',
    second: '#ABD900',
    third: '#0B2265'
  },
  grey: {
    first: '#EEEEEE',
    second: '#3C3C3B',
    third: '#ACAAAA',
    fourth: '#F8F8F8',
    fifth: '#D0D0D0',
    sixth: '#515151',
    seventh: '#706f6f',
    eight: '#9d9d9c',
    ninth: '#E8E8E8'
  },
  default: {
    white: '#FFFFFF',
    black: '#000000'
  }
};
