import { palette } from '../palette/Palette';

const Switch = {
  switch: {
    borderColor: palette.grey.fifth,
    backgroundColor: palette.grey.fifth
  },

  switchAfter: {
    backgroundColor: palette.grey.first
  },

  checkedAfter: {
    backgroundColor: palette.primary.first
  }
};
export default Switch;
