import { palette } from '../palette/Palette';

const SliderGalleryImage = {
  singleImageInnerSm: {
    maxWidth: 295,
    marginRight: 20,
    display: 'grid',
    msGridRows: '70% 30%',
    gridTemplateRows: '3fr'
  },
  imageSm: {
    marginBottom: 13,
    gridRow: 1
  },

  cardNumbersSm: {
    display: 'inline-block',
    position: 'relative',
    background: 'none',
    '& span': {
      color: palette.grey.third,
      fontStyle: 'italic'
    }
  },
  sliderDot: {
    color: palette.grey.third,
    '&:before': {
      content: '"•" ',
      fontFamily: 'slick',
      fontSize: 36,
      position: 'absolute',
      textAlign: 'center',
      cursor: 'pointer',
      '-webkit-font-smoothing': 'antialiased',
      color: palette.grey.third
    }
  },
  sliderDotActive: {
    '&:before': {
      color: palette.primary.first,
      backgroundColor: palette.primary.first
    }
  },
  buttonColor: {
    borderRadius: 0,
    cursor: 'pointer',
    height: 50,
    backgroundColor: palette.secondary.first,
    '& p': {
      color: palette.primary.third
    }
  },
  noTitle: {
    paddingTop: 48
  },
  sliderItem: {
    '& H2': {
      maxHeight: 28,
      overflow: 'hidden'
    }
  }
};

export default SliderGalleryImage;
