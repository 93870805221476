import { palette } from '../palette/Palette';

const HeaderSection = {
  root: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    height: 638,
    verticalAlign: 'middle',
    overflow: 'hidden',
    marginBottom: 80,
    '& $imageWrapper': {
      width: 638
    },
    '& $pngImage': {
      width: 723
    }
  },
  rootLg: {
    '& $imageWrapper': {
      width: 525
    },
    '& $pngImage': {
      width: 650
    }
  },
  campaignHeaderRoot: {
    height: 507,
    '& $imageWrapper': {
      width: 719
    },
    '& $pngImage': {
      width: 574,
      marginRight: 147
    },
    '& $video': {
      width: 719
    }
  },

  backgroundImageWrapper: {
    height: '100%',
    padding: '42.368% 0 0 0',
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    lineHeight: 0
  },
  image: {
    top: 0,
    right: 0,
    left: 'unset',
    display: 'block',
    position: 'absolute'
  },
  imageWrapper: {
    top: 0,
    right: 0,
    height: '100%',
    position: 'absolute',
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  imageWrapperHeaderBackground: {},
  pngImageHeaderBackground: {},
  contentOuterWrapper: {
    top: 0,
    width: 'calc(100% - 720px)',
    height: '100%',
    position: 'absolute'
  },
  contentOuterWrapperLg: {
    width: 'calc(100% - 650px)'
  },
  campaignHeaderRootLg: {
    '& $imageWrapper': {
      width: 580
    },
    '& $pngImage': {
      width: 455,
      marginRight: 40
    },
    '& $video': {
      width: 525
    }
  },
  campaignHeaderRootMd: {
    height: 302,
    '& $imageWrapper': {
      width: 342
    },
    '& $pngImage': {
      width: 336,
      marginRight: 40
    },
    '& $video': {
      width: 342
    }
  },
  campaignHeaderRootSm: {
    width: '100%',
    height: 'inherit',
    marginBottom: 20,
    '& $imageWrapper': {
      height: 231,
      width: '100%'
    },
    '& $pngImage': {
      height: 231,
      width: 263,
      margin: '0 36px 0 75px',
      right: 'auto'
    }
  },
  rootSm: {
    '& $imageWrapper': {
      height: 231,
      width: '100%'
    },
    '& $pngImage': {
      height: 231,
      width: 'calc(100% - 100px)',
      margin: '0 50px'
    }
  },
  imageBackground: {
    top: 0,
    right: 0,
    display: 'block',
    position: 'absolute'
  },
  imageBackgroundSm: {
    height: 231
  },
  contentOuterWrapperMd: {
    width: 'calc(100% - 380px)'
  },
  contentOuterWrapperSm: {
    width: '100%',
    height: 'calc(100% - 231px)',
    boxSizing: 'border-box',
    paddingLeft: 20,
    paddingRight: 25,
    margin: 0,
    backgroundColor: palette.default.white,
    position: 'relative'
  },

  link: {
    display: 'inline-block',
    marginTop: 46,
    '& button': {
      backgroundColor: palette.secondary.first,
      color: palette.primary.third,
      padding: '10px 13px 7px',
      minHeight: 36,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: palette.secondary.second
      }
    }
  },

  secondLink: {
    display: 'inline-block',
    marginTop: 46,
    '& button': {
      backgroundColor: palette.primary.first,
      padding: '10px 13px 7px',
      minHeight: 36,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: palette.primary.first
      },
      '& *:first-child': {
        color: palette.default.white
      }
    }
  },

  contentWrapper: {
    width: '100%',
    display: 'inline-block',
    padding: '76px 20%'
  },
  contentWrapperLg: {
    padding: '52px 20%'
  },
  contentWrapperMd: {
    padding: '52px 20%'
  },
  contentWrapperSm: {
    padding: '22px 0 0',
    width: '100%'
  },
  title: {
    color: palette.secondary.first
  },
  titleSecondRow: {
    color: palette.default.white
  },
  titleThirdRow: {
    color: palette.default.white
  },
  highlight: {
    color: palette.default.white
  },
  secondLinkMd: {
    marginTop: 15,
    '& button': {
      padding: '6px 9px 4px 10px',
      minHeight: 30
    }
  },
  secondLinkSm: {
    marginTop: 13,
    marginBottom: 20,
    '& button': {
      padding: '6px 9px 4px 10px',
      minHeight: 26
    }
  },
  rootMd: {
    width: '100%',
    height: 570,
    marginBottom: 80,
    '& $imageWrapper': {
      width: 371
    },
    '& $pngImage': {
      width: 415
    },
    '& $video': {
      width: 371
    }
  },
  linkMd: {
    marginTop: 15,
    '& button': {
      padding: '6px 9px 4px 10px',
      minHeight: 30
    }
  },
  linkSm: {
    marginTop: 13,
    marginBottom: 20,
    '& button': {
      padding: '6px 9px 4px 10px',
      minHeight: 26
    }
  },
  button: {
    marginBottom: 0
  },
  highlightSm: {
    color: palette.grey.second
  },
  imageSm: {
    height: 231
  },
  backgroundImageWrapperXs: {
    padding: 0,
    height: 231
  },
  backgroundImageWrapperVideoXs: {
    width: 0
  },
  video: {
    top: 0,
    right: 0,
    position: 'absolute',
    width: 638
  },
  videoLg: {
    width: 500
  },
  videoSm: {
    width: '100%'
  },
  sectionTitle: {
    marginBottom: 29
  },
  sectionTitleMd: {
    marginBottom: 9
  },
  sectionTitleSm: {
    marginBottom: 9,
    '& h1': {
      color: palette.secondary.third
    }
  },

  imageWrapperSm: {
    padding: '44.26% 0 0 0'
  },
  contentWrapperLl: {
    padding: '20px 16% 20px 5.9%'
  },

  contentWrapperXs: {
    width: '100%'
  },

  contentBasicWrapper: {
    '& $p': {
      color: palette.default.black
    }
  },

  textLink: {
    textTransform: 'uppercase'
  },

  basicHighlight: {
    display: 'block'
  },

  basicHighlightMd: {
    display: 'block'
  },

  titleMd: {
    margin: '11px 0 0'
  },

  titleSm: {
    margin: '19px 0 0'
  },

  highlightMd: {
    display: 'none'
  },

  shapeOuter: {
    width: '80.5%'
  },

  shapeOuterSm: {
    width: '95.7%',
    bottom: -10
  },

  shapeOuterXs: {
    bottom: -4
  },

  shapeWrapper: {
    paddingTop: '21.15%'
  },

  shapeWrapperMd: {
    paddingTop: '27%'
  },

  shapeWrapperSm: {
    paddingTop: '13.6%'
  },

  shape: {
    margin: '-21% 0 0',
    left: -2,
    bottom: -2
  },

  shapeMd: {
    margin: '-26.5% 0 0'
  },

  shapeSm: {
    margin: '-10.6% 0 0',
    left: -10
  },

  secondButton: {
    '& span': {
      textTransform: 'uppercase'
    }
  }
};

export default HeaderSection;
