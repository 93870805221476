import { palette } from '../palette/Palette';

const HeaderSectionSlider = {
  arrowSvgHover: {
    '& circle': {
      fill: palette.primary.fourth
    }
  },

  dotHoverSelected: {
    background: palette.primary.fourth
  },

  dotHover: {
    background: palette.grey.first
  }
};

export default HeaderSectionSlider;
