import { palette } from '../palette/Palette';

const Modal = {
  modalHeader: {
    backgroundColor: palette.primary.first,
    color: palette.default.white
  },

  modalBodyTitle: {
    color: palette.grey.second
  },

  modalHeaderSm: {
    '& p': {
      fontSize: '0.9375em'
    }
  },

  firstButton: {
    display: 'flex',
    alignItems: 'center',
    width: 200,
    height: 52,
    '& $p': {
      color: palette.primary.third
    },
    '& $svg': {
      '& path': {
        fill: palette.primary.third
      }
    }
  },

  firstButtonMd: {
    width: 200,
    minHeight: 44,
    '& svg': {
      width: 20,
      height: 20,
      marginRight: 15
    }
  },

  firstButtonSm: {
    width: 200,
    height: 44,
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 10
    }
  }
};

export default Modal;
