import { palette } from '../../palette/Palette';

const StationRow = {
  title: {
    color: palette.grey.third,
    fontFamily: 'Sky-Bold',
    fontSize: 18,
    lineHeight: '24px',
    textTransform: 'uppercase',
    marginBottom: 0
  }
};

export default StationRow;
