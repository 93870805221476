import { palette } from '@theme/palette/Palette';

const ArticleHeader = {
  title: {
    color: palette.primary.second
  },

  categoryHolder: {
    paddingRight: 16
  },
  imageCaption: {
    color: palette.default.black
  },
  imageCaptionMd: {
    paddingLeft: 32
  },

  iconCalendar: {
    width: 20,
    height: 20
  },

  categoryHolderSm: {
    paddingRight: 8
  },

  iconHolderXs: {
    paddingRight: 8
  }
};

export default ArticleHeader;
