import React from 'react';

const iconNewSliderNext = (
  <g>
    <defs>
      <circle id="a" cx="32" cy="32" r="32" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="32" cy="32" r="32" />
      <circle fill="#007CDF" cx="32" cy="32" r="32" />
      <g fill="#FFF">
        <path d="m26.867 46.787-3.091-3.093a.73.73 0 0 1 0-1.03l10.149-10.149a.727.727 0 0 0 0-1.03l-10.15-10.15a.73.73 0 0 1 0-1.03l3.092-3.092a.73.73 0 0 1 1.03 0l14.272 14.272a.73.73 0 0 1 0 1.03L27.897 46.787a.73.73 0 0 1-1.03 0z" />
      </g>
    </g>
  </g>
);

export default iconNewSliderNext;
