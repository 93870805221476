import React from 'react';

const universalVotingIconPlay = (
  <g
    id="assets/station-no-cover-Sky-Radio-1"
    transform="translate(-45.000000, -41.000000)"
    fill="#FFFFFF"
  >
    <g
      id="sky-radio-/-icons-/-general-/-play"
      transform="translate(22.000000, 22.000000)"
    >
      <path
        d="M25.17336,20.31102 C26.27136,19.65762 27.60336,19.62882 28.72656,20.23722 L28.72656,20.23722 L50.29416,31.89402 C51.46956,32.52942 52.20036,33.75882 52.20036,35.10162 C52.20036,36.44442 51.46956,37.67382 50.29416,38.30922 L50.29416,38.30922 L28.72656,49.96602 C28.18656,50.25762 27.59976,50.40342 27.01476,50.40342 C26.37936,50.40342 25.74576,50.23242 25.17336,49.89222 C24.06276,49.22982 23.40036,48.05802 23.40036,46.75842 L23.40036,46.75842 L23.40036,23.44482 C23.40036,22.14522 24.06276,20.97342 25.17336,20.31102 Z M27.01476,23.40522 L27.00036,46.75842 L48.58236,35.14302 C48.59136,35.13762 48.59496,35.13582 48.59496,35.13582 L48.59496,35.13582 L27.01476,23.40522 Z"
        id="Combined-Shape"
      />
    </g>
  </g>
);

export default universalVotingIconPlay;
