import { palette } from '../palette/Palette';

const Modal = {
  modalHeader: {
    backgroundColor: palette.primary.first,
    color: palette.default.white
  },
  modalButton: {
    backgroundColor: palette.primary.first,
    minHeight: 37,
    height: 37,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: palette.primary.second
    },
    '& p': {
      color: `${palette.default.white} !important`
    }
  },

  firstButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 37,
    height: 37
  },

  firstButtonMd: {
    minHeight: 30,
    height: 30
  },

  firstButtonSm: {
    minHeight: 26,
    height: 26
  },

  modalButtonMd: {
    minHeight: 30,
    height: 30
  },

  modalButtonSm: {
    minHeight: 26,
    height: 26
  },
  modalButtonRow: {
    '& button': {
      textTransform: 'uppercase'
    }
  },
  modalBodyTitle: {
    textTransform: 'uppercase'
  }
};

export default Modal;
