import { palette } from '../../palette/Palette';

const Playlist = {
  showMore: {
    color: palette.primary.first,
    fontFamily: 'Sky-Bold',
    fontSize: 15,
    lineHeight: 1.2
  },

  hover: {
    color: palette.primary.second
  },

  hoverAfter: {
    backgroundColor: palette.primary.second
  },

  before: {
    borderLeft: `dotted 2px ${palette.grey.third}`
  },

  after: {
    backgroundColor: palette.primary.first
  },

  trackPlayTime: {
    color: palette.grey.third,
    fontFamily: 'Sky-Regular',
    fontSize: 14,
    lineHeight: 1
  },

  nowPlaying: {
    color: palette.default.black,
    fontFamily: 'Sky-Bold',
    fontSize: 14,
    lineHeight: 1
  },
  listName: {
    backgroundColor: palette.secondary.first,
    color: palette.primary.third
  }
};

export default Playlist;
