import { palette } from '../palette/Palette';

const Toaster = {
  wrapper: {
    backgroundColor: palette.default.white
  },

  verticalLine: {
    borderColor: palette.primary.first
  },

  bodyLink: {
    color: palette.primary.first
  },

  bodyText: {
    color: palette.grey.third
  },

  bar: {
    backgroundColor: palette.primary.first
  },

  progress: {
    backgroundColor: palette.default.white
  },

  closeSign: {
    fill: palette.primary.first
  },
  title: {
    textTransform: 'uppercase'
  }
};

export default Toaster;
