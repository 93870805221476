import { palette } from '../palette/Palette';

const NavigationSkeleton = {
  wrapper: {
    backgroundColor: palette.primary.first
  },

  logo: {
    viewBox: '0 0 135 67'
  }
};

export default NavigationSkeleton;
