import React from 'react';

const iconPlus = (
  <path
    d="M60.444 28.444H35.556V3.556A3.555 3.555 0 0 0 32 0a3.555 3.555 0 0 0-3.556 3.556v24.888H3.556A3.555 3.555 0 0 0 0 32a3.555 3.555 0 0 0 3.556 3.556h24.888v24.888A3.555 3.555 0 0 0 32 64a3.555 3.555 0 0 0 3.556-3.556V35.556h24.888A3.555 3.555 0 0 0 64 32a3.555 3.555 0 0 0-3.556-3.556"
    fill="#FFF"
  />
);

export default iconPlus;
