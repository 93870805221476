import React from 'react';

const iconPause = (
  <g fill="none" fillRule="evenodd">
    <g>
      <circle cx="32" cy="32" r="32" />
      <circle fill="#007CDF" cx="32" cy="32" r="32" />
    </g>
    <path
      d="M29.514 17v30h-7.826V17h7.826zm13.043 0v30h-7.826V17h7.826z"
      fill="#FFF"
    />
  </g>
);

export default iconPause;
