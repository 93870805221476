import { palette } from '../palette/Palette';

const StationCard = {
  singleStation: {
    width: '25%',
    padding: '0 20px',
    margin: '0 0 40px',
    '&:nth-child(4n+1)': {
      clear: 'both'
    }
  },

  singleStationMl: {
    width: '33.3%',
    padding: '0 12px',
    margin: '0 0 24px',
    '&:nth-child(3n+1)': {
      clear: 'both'
    },
    '&:nth-child(4n+1)': {
      clear: 'none'
    }
  },

  overlay: {
    bottom: '12%',
    right: 0,
    paddingMd: '0 0 20px',
    textAlign: 'right',
    width: '100%'
  },

  logoHolder: {
    textAlign: 'center',
    padding: '0 2px',
    top: '40%'
  },

  logoHolderSm: {
    top: '38%'
  },

  imageOverlay: {
    backgroundImage: `linear-gradient(119deg, ${palette.primary.first}, ${palette.primary.second})`
  },

  imageOverlayHover: {
    opacity: 0.87
  },

  stationTitle: {
    color: palette.primary.first
  },

  singleStationInnerHolderListHover: {
    backgroundColor: palette.grey.first
  },

  textOverlay: {
    width: '100%',
    height: '130px',
    opacity: '0.5',
    bottom: 5,
    position: 'absolute',
    backgroundImage:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3) 35%, rgba(0, 0, 0, 0.5))'
  },

  playIcon: {
    '& circle': {
      fill: palette.default.white
    },
    '& g': {
      fill: palette.primary.first
    }
  },

  playIconHover: {
    '& circle': {
      fill: palette.primary.second
    },
    '& g': {
      fill: palette.default.white
    }
  },

  textHolder: {
    width: '100%'
  }
};

export default StationCard;
