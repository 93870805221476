import { palette } from '../../palette/Palette';

const Cover = {
  wrapper: {
    marginBottom: 15
  },

  tile: {
    borderRadius: '1.5%',
    height: 276,
    width: 276,
    marginBottom: 19
  },

  tileMobile: {
    height: 255,
    width: 255
  },

  image: {
    background: '#b9d300'
  },

  stationName: {
    color: palette.default.white,
    fontFamily: 'Sky-Bold',
    fontSize: 27,
    lineHeight: 1
  },

  showTitle: {
    fontFamily: 'Sky-Bold',
    color: palette.default.white,
    fontSize: 16,
    lineHeight: 1
  },

  showTime: {
    fontFamily: 'Sky-Bold'
  },

  textTitle: {
    color: palette.primary.third,
    fontFamily: 'Sky-Bold',
    fontSize: 16,
    lineHeight: 1.25,
    marginBottom: 5,
    maxWidth: 276,
    textTransform: 'uppercase'
  },

  textArtist: {
    color: palette.grey.third,
    fontFamily: 'Sky-Bold',
    fontSize: 15,
    lineHeight: 1.2,
    textTransform: 'none',
    marginBottom: 7.5,
    maxWidth: 276
  },

  textMobile: {
    maxWidth: 255
  }
};

export default Cover;
