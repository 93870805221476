import { palette } from '../palette/Palette';

const Footer = {
  footer: {
    backgroundColor: palette.grey.second,
    marginTop: 0,
    borderTop: `1px solid ${palette.grey.seventh}`
  },

  holder: {
    padding: '61px 0 56px',
    paddingMd: '41px 0 140px',
    paddingSm: '41px 0 105px'
  },

  topContainer: {
    padding: '0 0 63px'
  },

  contact: {
    margin: '20px 0 0',
    marginSm: '16px 0 0 6px',
    marginSmFirstChild: '20px 0 0 6px'
  },

  contactItem: {
    padding: '0 0 0 38px'
  },

  footnote: {
    padding: '0 0 0 38px',
    paddingSm: '0 0 0 44px'
  },

  footerMenuItem: {
    padding: '20px 10px 0 0',
    '&:hover': {
      '& p': {
        color: palette.grey.third
      }
    }
  },

  footerCenter: {
    paddingMd: '55px 0 0'
  },

  socialIcons: {
    paddingMd: '48px 0',
    paddingSm: '32px 0'
  },

  socialIcon: {
    margin: '0 16px 16px 0',
    backgroundColor: palette.primary.first,
    borderRadius: 2,
    height: 40,
    width: 40,

    '&:hover': {
      backgroundColor: palette.primary.third
    }
  },

  copyright: {
    paddingMd: '0 0 20px',
    paddingSm: '0 0 10px'
  },

  footerHolder: {
    padding: '60px 0 0'
  },

  footerForm: {
    paddingSm: '0 0 63px'
  },

  smallLink: {},

  otherLink: {
    padding: '0 20px 0 0',
    paddingSm: '0 10px 10px 0',
    '&:hover': {
      '& span': {
        color: palette.default.white
      }
    },
    '& $smallLink': {
      color: palette.grey.third
    }
  },

  footnoteSm: {
    padding: '0 0 0 44px'
  },

  contactsAndFootnote: {
    '& $footnote': {
      color: palette.grey.third
    }
  },

  contactIcons: {
    width: 32,
    height: 32
  },

  socialFooterIcons: {
    width: 40,
    height: 40
  },

  iconInner: {
    height: 19,
    width: 19
  }
};

export default Footer;
