import { palette } from '../palette/Palette';

const ChartList = {
  chartListTop: {
    backgroundColor: palette.default.white
  },
  chartListSearch: {
    backgroundColor: palette.default.white
  },

  chartListHeaderContainer: {
    backgroundColor: palette.primary.second,
    color: palette.default.white
  },

  chartListTableRow: {
    height: '100px',
    oddColor: palette.default.white,
    evenColor: 'rgba(208, 208, 208, 0.15)'
  },

  chartListTableRowMd: {
    height: '80px'
  },

  fixed: {
    headerContainer: {
      backgroundColor: palette.default.white,
      color: palette.primary.second
    },

    chartListHeader: {
      width: '83.71%',
      maxWidth: '1280px'
    },

    chartListHeaderLg: {
      width: '88%'
    },

    chartListSearch: {
      padding: '0 0 23px'
    },

    articleHeaderWrapper: {
      width: '83.71%'
    },

    articleHeaderOuter: {
      width: 'calc(100% - 290px)'
    },

    articleHeaderInner: {
      paddingRight: 90
    },

    articleChartListHeaderContainer: {
      maxWidth: 820
    },

    articleSearch: {
      maxWidth: 880
    }
  },

  chartListTableWrapper: {
    '& span': {
      color: palette.grey.second
    },

    '& p': {
      color: palette.grey.second
    }
  },

  headerTypography: {
    color: palette.primary.second
  },

  chartListHeaderRow: {
    borderBottom: `1px solid ${palette.grey.fourth}`
  }
};

export default ChartList;
