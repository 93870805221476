import { palette } from '../palette/Palette';

const UniversalVotingEndScreen = {
  lineSeparator: {
    borderBottom: `2px solid ${palette.grey.fourth}`
  },

  bodyText: {
    '& h2': {
      color: palette.grey.second,
      textTransform: 'uppercase',
      fontSize: 23
    },

    link: {
      color: palette.primary.first
    },
    subscript: {
      color: palette.grey.second
    }
  },

  confirmationEndText: {
    textTransform: 'uppercase'
  },

  titleEndScreen: {
    color: palette.primary.second
  }
};

export default UniversalVotingEndScreen;
