import { palette } from '../palette/Palette';

const UniversalVotingAddTrack = {
  wrapper: {
    border: `solid 1px ${palette.grey.first}`,
    borderRadius: 0
  },

  returnButton: {
    color: palette.primary.first
  },

  divider: {
    color: palette.grey.first,
    backgroundColor: palette.grey.first
  },

  inputTypeText: {
    borderColor: palette.grey.fourth,
    backgroundColor: palette.grey.fourth,
    '&:focus': {
      outline: 'none',
      border: `1px solid ${palette.primary.first}`
    },
    '&::placeholder': {
      color: palette.grey.eight
    }
  },

  freeChoiceMotivationText: {
    '& p': {
      fontFamily: 'Sky-Regular'
    }
  },

  title: {
    whiteSpace: 'nowrap'
  },

  artist: {
    textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  }
};
export default UniversalVotingAddTrack;
