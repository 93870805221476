import { palette } from '../../palette/Palette';

const TrackTile = {
  title: {
    color: palette.grey.third,
    fontFamily: 'Sky-Bold',
    fontSize: 15,
    lineHeight: '20px',
    textTransform: 'none'
  },
  artist: {
    color: palette.grey.third,
    fontFamily: 'Sky-Bold',
    textTransform: 'none',
    fontSize: 12,
    lineHeight: '14px'
  },
  titleStations: {
    fontFamily: 'Sky-Bold',
    fontSize: 15,
    lineHeight: 1.2
  },
  artistStation: {
    fontSize: 12,
    lineHeight: 1.2
  },
  rankingPosition: {
    backgroundColor: palette.secondary.first
  },
  rankingPositionText: {
    color: palette.default.black
  },
  trackNumberContainer: {
    backgroundColor: palette.secondary.first
  }
};

export default TrackTile;
