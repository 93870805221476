import { palette } from '../palette/Palette';

const TextFrameStyles = {
  textFrame: {
    backgroundColor: palette.grey.first,
    maxWidth: 800,
    padding: '63px 42px'
  },

  textFrameMd: {
    maxWidth: 708,
    padding: '47px 42px'
  },

  textFrameSm: {
    maxWidth: 375,
    padding: '40px 22px'
  },

  title: {
    paddingBottom: 20
  },

  titleMd: {
    paddingBottom: 19
  },

  titleSm: {
    paddingBottom: 20
  },
  button: {
    padding: 16
  },
  buttonMd: {
    padding: '15px 16px'
  },
  buttonSm: {
    padding: '14px 16px'
  }
};

export default TextFrameStyles;
