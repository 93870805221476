import { palette } from '../palette/Palette';

const SingleClip = {
  iconSize: 40,
  iconSizeMd: 34,
  iconSizeSm: 28,
  imageSize: 112,
  imageSizeSm: 56,

  audioHolderInner: {
    backgroundColor: palette.grey.first
  },
  trackTitle: {
    color: palette.primary.second
  }
};

export default SingleClip;
