import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconTextVoting = (
  <path
    d="M0.15625,-1.71848581e-17 L19.84375,-1.71848581e-17 C19.9300445,-1.71848581e-17 20,0.0693006981 20,0.154787441 L20,15.0143818 C20,15.0998686 19.9300445,15.1691693 19.84375,15.1691693 L14.375,15.1691693 L10,19.8127925 L5.625,15.1691693 L0.15625,15.1691693 C0.0699555078,15.1691693 0,15.0998686 0,15.0143818 L0,0.154787441 C0,0.0693006981 0.0699555078,-1.71848581e-17 0.15625,-1.71848581e-17 Z M1.5625,1.70266186 L1.5625,13.4665074 C1.5625,13.5519942 1.63245551,13.6212949 1.71875,13.6212949 L6.25,13.6212949 L10,17.6457683 L13.75,13.6212949 L18.28125,13.6212949 C18.3675445,13.6212949 18.4375,13.5519942 18.4375,13.4665074 L18.4375,1.70266186 C18.4375,1.61717511 18.3675445,1.54787441 18.28125,1.54787441 L1.71875,1.54787441 C1.63245551,1.54787441 1.5625,1.61717511 1.5625,1.70266186 Z M3.28125,3.40532371 L16.71875,3.40532371 C16.8050445,3.40532371 16.875,3.49194959 16.875,3.59880801 L16.875,4.75971383 C16.875,4.86657226 16.8050445,4.95319813 16.71875,4.95319813 L3.28125,4.95319813 C3.19495551,4.95319813 3.125,4.86657226 3.125,4.75971383 L3.125,3.59880801 C3.125,3.49194959 3.19495551,3.40532371 3.28125,3.40532371 Z M3.28125,6.50107254 L16.71875,6.50107254 C16.8050445,6.50107254 16.875,6.58769842 16.875,6.69455684 L16.875,7.85546266 C16.875,7.96232109 16.8050445,8.04894696 16.71875,8.04894696 L3.28125,8.04894696 C3.19495551,8.04894696 3.125,7.96232109 3.125,7.85546266 L3.125,6.69455684 C3.125,6.58769842 3.19495551,6.50107254 3.28125,6.50107254 Z M3.28125,9.59682137 L12.96875,9.59682137 C13.0550445,9.59682137 13.125,9.68344725 13.125,9.79030567 L13.125,10.9512115 C13.125,11.0580699 13.0550445,11.1446958 12.96875,11.1446958 L3.28125,11.1446958 C3.19495551,11.1446958 3.125,11.0580699 3.125,10.9512115 L3.125,9.79030567 C3.125,9.68344725 3.19495551,9.59682137 3.28125,9.59682137 Z"
    fill={palette.grey.second}
  />
);

export default iconTextVoting;
