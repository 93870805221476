import React from 'react';

const iconNewSliderBack = (
  <g>
    <defs>
      <circle id="a" cx="32" cy="32" r="32" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="32" cy="32" r="32" />
      <circle fill="#007CDF" cx="32" cy="32" r="32" />
      <g fill="#FFF">
        <path d="M37.203 46.787a.73.73 0 0 1-1.03 0L21.9 32.515a.73.73 0 0 1 0-1.03l14.271-14.272a.73.73 0 0 1 1.03 0l3.092 3.093a.73.73 0 0 1 0 1.03l-10.15 10.149a.727.727 0 0 0 0 1.03l10.15 10.15a.73.73 0 0 1 0 1.03l-3.091 3.092z" />
      </g>
    </g>
  </g>
);

export default iconNewSliderBack;
