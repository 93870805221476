import { palette } from '../palette/Palette';

const Navigation = {
  searchActive: {},

  menuHolder: {
    '&:hover': {
      '& $menuText $menuTextTypography': {
        color: palette.secondary.first
      },
      '& $menuIcon $svgMenu $svg $path': {
        fill: palette.secondary.first
      }
    }
  },

  searchHolder: {
    '&:hover': {
      '& $searchIcon': {
        '& svg': {
          '& path': {
            fill: palette.secondary.first
          }
        }
      },
      '& $searchText': {
        '& p': {
          color: palette.secondary.first
        }
      }
    }
  },

  navItemLink: {
    '&:hover': {
      color: palette.secondary.first
    }
  },

  playButton: {
    backgroundColor: palette.secondary.first,
    color: palette.primary.third,
    '&:hover': {
      background: palette.secondary.second
    }
  },

  iconPlayButton: {
    height: 14,
    width: 14,
    paddingRight: 8,
    '& path': {
      fill: palette.primary.third
    }
  },

  iconPlayButtonMd: {
    height: 12,
    width: 12,
    paddingRight: 10
  },

  mainHeader: {
    height: 72,
    backgroundColor: palette.primary.first,
    flex: 1,
    color: palette.default.white
  },
  mainHeaderMd: {
    height: 60
  },
  svgLogo: {
    width: 97,
    height: 49,
    viewBox: '0 0 135 67'
  },
  svgLogoMd: {
    width: 81,
    height: 41
  },
  svgLogoSm: {
    width: 73,
    height: 36
  },
  svgClose: {
    width: 32,
    height: 32
  },
  svgMenu: {
    width: 32,
    height: 32
  },
  svgSearch: {
    width: 32,
    height: 32
  },
  searchIcon: {
    '& $svgSearch': {
      fill: palette.default.white
    }
  },
  searchIconMd: {
    '& svg': {
      width: 24,
      height: 24
    }
  },
  iconClose: {
    '& $path': {
      fill: palette.default.white
    }
  },
  menuIconMd: {
    '& svg': {
      width: 24,
      height: 24
    }
  },
  logIn: {
    width: 327
  },
  logInMd: {
    width: 72
  },
  userProfile: {
    width: 320
  },
  logoHolderMd: {
    left: 'calc(50% + 20px)'
  },
  menuText: {
    textTransform: 'capitalize'
  },

  searchText: {},

  searchTextTypography: {
    textTransform: 'capitalize'
  },

  navItemTabletContainer: {
    order: 1,
    flex: '1 1 100%',
    width: '100%'
  },

  searchLinkOverlay: {
    left: 0,
    position: 'relative',
    marginLeft: 'auto'
  },
  navItemsTabletWrapper: {
    backgroundColor: palette.default.white
  },
  navItemsTabletWrapperMd: {
    height: 52,
    flexWrap: 'wrap'
  },

  navItemsTabletWrapperSm: {
    height: 48
  },

  navMenuItemMd: {
    padding: '0 16px'
  },

  navMenuItemSm: {
    padding: '0 10px'
  }
};

export default Navigation;
