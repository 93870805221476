import React from 'react';

const iconClose = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M38.4649763,32 L62.6610596,56.1960833 C64.4463135,57.9813372 64.4463135,60.8758057 62.6610596,62.6610596 C60.8758057,64.4463135 57.9813372,64.4463135 56.1960833,62.6610596 L32,38.4649763 L7.80391671,62.6610596 C6.01866281,64.4463135 3.12419433,64.4463135 1.33894043,62.6610596 C-0.446313476,60.8758057 -0.446313476,57.9813372 1.33894043,56.1960833 L25.5350237,32 L1.33894043,7.80391671 C-0.446313476,6.01866281 -0.446313476,3.12419433 1.33894043,1.33894043 C3.12419433,-0.446313476 6.01866281,-0.446313476 7.80391671,1.33894043 L32,25.5350237 L56.1960833,1.33894043 C57.9813372,-0.446313476 60.8758057,-0.446313476 62.6610596,1.33894043 C64.4463135,3.12419433 64.4463135,6.01866281 62.6610596,7.80391671 L38.4649763,32 Z"
    id="icon-close"
  />
);

export default iconClose;
