import { palette } from '../palette/Palette';

const UniversalVotingLogin = {
  link: {
    color: palette.primary.first,
    borderBottom: `1px solid ${palette.grey.fourth}`,
    cursor: 'pointer'
  },

  logo: {
    width: 140,
    height: 24,
    viewBox: '0 0 176 30'
  },

  logoSm: {
    width: 93,
    height: 16
  },

  loginButton: {
    textTransform: 'uppercase'
  }
};

export default UniversalVotingLogin;
