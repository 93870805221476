import React from 'react';

const iconStop = (
  <g fill="none" fillRule="evenodd">
    <g>
      <circle cx="32" cy="32" r="32" />
      <circle fill="#007CDF" cx="32" cy="32" r="32" />
    </g>
    <path d="M19.813 19.813h24.375v24.375H19.811z" />
    <path fill="#FFF" d="M19.813 19.813h24.375v24.375H19.811z" />
    <path d="M19.813 19.813h24.375v24.375H19.811z" />
  </g>
);

export default iconStop;
