import React from 'react';

const iconNowPlaying = (
  <g fill="none" fillRule="evenodd">
    <g fill="#FFF">
      <path d="M54.384 9.616c.488.488.488 1.28 0 1.768s-1.28.488-1.768 0C41.25.018 22.751.017 11.384 11.384 4.026 18.742 1.235 29.35 3.774 39.276c1.284 5.016 3.876 9.606 7.61 13.34 6.095 6.095 14.443 9.093 22.897 8.436 6.885-.535 13.364-3.465 18.335-8.436.488-.488 1.28-.488 1.768 0s.488 1.28 0 1.768c-5.397 5.397-12.436 8.58-19.91 9.16-9.174.713-18.24-2.542-24.858-9.16-4.053-4.054-6.87-9.041-8.263-14.488-2.756-10.771.273-22.29 8.263-30.28 12.343-12.343 32.425-12.342 44.768 0zm-28.795 9.208L46.293 32 25.59 45.176V18.824z" />
    </g>
  </g>
);

export default iconNowPlaying;
