import { palette } from '../../palette/Palette';

const PlaylistLink = {
  link: {
    color: palette.primary.first,
    fontFamily: 'Sky-Bold',
    lineHeight: 1,
    fontSize: 20,
    textTransform: 'none'
  },
  linkHover: {
    color: '#0070a0'
  }
};

export default PlaylistLink;
