import { palette } from '../palette/Palette';

const Badge = {
  root: {
    backgroundColor: palette.secondary.first
  },

  exclusiveMd: {
    padding: '6px 15px'
  },

  newEpisode: {
    padding: '3px 10px'
  },

  programGuide: {
    top: 20,
    right: 20,
    backgroundColor: palette.secondary.first
  },

  programGuideMd: {
    top: 15,
    right: 15
  },
  programGuideSm: {
    width: 45
  }
};

export default Badge;
