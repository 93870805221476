import { palette } from '../../palette/Palette';

const Tab = {
  link: {
    color: palette.primary.first,
    fontFamily: 'Sky-Bold',
    fontSize: 17,
    lineHeight: 1.41,
    padding: 15
  },
  linkHover: {
    color: palette.primary.first,
    borderBottomColor: palette.primary.first
  },
  linkSelected: {
    color: palette.primary.third,
    borderBottomColor: palette.primary.third
  }
};

export default Tab;
