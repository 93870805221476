import { palette } from '../palette/Palette';

const RelatedArticles = {
  relatedTitle: {
    '& h2': {
      color: palette.primary.second
    },
    paddingBottom: 32
  }
};

export default RelatedArticles;
