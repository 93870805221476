import { palette } from '../../palette/Palette';

const Playlist = {
  wrapper: {
    backgroundColor: palette.default.white
  },

  mainPlayer: {
    backgroundColor: palette.grey.fourth
  },

  title: {
    color: palette.grey.third,
    fontFamily: 'Sky-Bold',
    fontSize: 21,
    lineHeight: 1.65,
    textTransform: 'titlecase'
  },

  link: {
    color: palette.primary.first,
    fontFamily: 'Sky-Bold',
    fontSize: 20,
    textTransform: 'none'
  },

  linkHover: {
    color: '#0070a0'
  },

  bar: {
    background: 'rgba(208, 208, 208, 0.15)',
    marginLeft: -30,
    marginRight: -30,
    paddingLeft: 30,
    paddingRight: 30
  }
};

export default Playlist;
