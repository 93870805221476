import { palette } from '../palette/Palette';

const Category = {
  tag: {
    padding: '6px 12px',
    backgroundColor: palette.primary.first,
    color: palette.default.white,
    '&:hover': {
      backgroundColor: palette.primary.third
    }
  },

  categoryMobile: {
    color: palette.primary.first,
    textTransform: 'uppercase',
    paddingBottom: 5
  },

  tagMd: {},

  tagSm: {
    paddingTop: 0,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 6,
    marginBottom: 0
  }
};

export default Category;
