const Lock = {
  exclusiveHolder: {
    display: 'none'
  },
  iconMemberSvg: {
    width: 70,
    height: 16,
    viewBox: '0 0 175 37'
  },
  iconMemberTopSvg: {
    width: 185,
    height: 64,
    viewBox: '0 0 185 64'
  },
  iconMemberBottomSvg: {
    width: 185,
    height: 64,
    viewBox: '0 0 185 64'
  }
};

export default Lock;
