import { palette } from '../palette/Palette';

const Clip = {
  bottomPlayOptions: {
    height: '21.4%',
    backgroundColor: palette.primary.first
  },

  audioHolder: {
    backgroundColor: palette.grey.first
  },

  imageOverlay: {
    backgroundColor: palette.primary.first
  },

  bottomPlayOptionsMd: {
    height: '35px'
  },

  notPlaying: {
    width: '72px'
  },

  notPlayingMd: {
    width: '56px'
  },

  notPlayingSm: {
    width: '46px'
  },

  playing: {
    width: '72px',
    paddingRight: '32px'
  },

  playingMd: {
    width: '88px',
    paddingRight: '21px'
  },

  playingSm: {
    width: '74px',
    paddingRight: '20px'
  },

  audioHolderInner: {
    maxHeight: 112
  },

  iconStop: {
    height: 40,
    width: 40
  },

  iconStopMd: {
    height: 35,
    width: 35
  },

  iconStopSm: {
    height: 26,
    width: 26
  },

  imageHolder: {
    width: '17.9%'
  },

  imageHolderMd: {
    width: 70
  },

  imageHolderSm: {
    width: 56
  },

  imageHolderLatest: {
    width: '37.1%'
  },

  imageHolderLatestMd: {
    width: 150
  },

  imageLatest: {
    maxWidth: 234
  },

  imageLatestMd: {
    maxWidth: 150
  },

  iconSpeaker: {
    padding: '0 28px 0 0',
    height: 20,
    width: 24
  },

  iconSpeakerMd: {
    padding: '0 11px 0 0',
    height: 17,
    width: 21
  },

  iconSpeakerSm: {
    padding: '0 10px 0 0',
    height: 14,
    width: 17
  },

  badgeHolder: {
    top: 13,
    left: 13,
    zIndex: 10
  },

  iconSpeakerLatest: {
    padding: '24px 0 0 30px',
    height: 20,
    width: 24
  },

  image: {
    maxWidth: '112px'
  },

  imageMd: {
    width: '70px'
  },

  imageSm: {
    width: '56px'
  },

  textHolderLatest: {
    height: '78.6%'
  },

  textHolderLatestMd: {
    height: 'calc(100% - 35px)'
  },

  iconPlayStopLatest: {
    height: 18,
    width: 18,
    padding: '0 2% 0 7%',
    '& g': {
      '& circle': {
        fill: palette.default.white
      },
      '& path': {
        fill: palette.primary.first
      },
      '& rect': {
        fill: palette.primary.first
      }
    }
  },

  iconPlayStopLatestMd: {
    height: 20,
    width: 20
  },

  iconPlay: {
    height: 40,
    width: 40
  },

  iconPlayMd: {
    height: 35,
    width: 35
  },

  iconPlaySm: {
    height: 26,
    width: 26
  },

  textHolderInner: {
    left: '7%'
  },

  textHolder: {
    padding: '1% 4%'
  },

  textHolderInnerSm: {
    left: '10px'
  },

  iconPlayLarge: {
    width: 60,
    height: 60
  }
};

export default Clip;
