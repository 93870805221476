import { palette } from '../palette/Palette';

const ImageSection = {
  root: {
    '&:hover': {
      '& button': {
        backgroundColor: palette.secondary.second
      }
    }
  },

  imageHolder: {
    paddingTop: '50%'
  },

  contentHolder: {
    width: '33.33%',
    maxWidth: 440,
    verticalAlign: 'middle'
  },

  logoHolder: {
    padding: '0 0 20px'
  },

  logoInner: {
    paddingTop: '35%'
  },

  textPositionRight: {
    left: 'auto',
    right: 0
  },

  subText: {
    color: palette.default.black,
    margin: '0 0 35px'
  },

  textColorLight: {
    color: palette.default.white
  },

  textColorDark: {
    color: palette.default.black
  },

  teaserText: {
    margin: '0 0 20px',
    color: palette.default.black
  },

  imageHolderMd: {
    paddingTop: '48.45%'
  },

  imageHolderSm: {
    display: 'block',
    textAlign: 'center',
    width: '100%',
    boxSizing: 'border-box',
    maxWidth: '100%'
  },

  contentHolderSm: {
    width: '100%',
    display: 'block',
    position: 'relative',
    maxWidth: '100%',
    textAlign: 'center'
  },

  backgroundLight: {
    backgroundColor: palette.grey.first
  },

  backgroundDark: {
    backgroundColor: palette.primary.first
  }
};

export default ImageSection;
