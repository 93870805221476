import React from 'react';

const iconQuote = (
  <path
    d="M64 12.06c0 .757-.19 1.514-1.136 2.271-3.787 3.787-7.763 9.09-7.763 13.633 0 5.681 7.574 9.847 7.574 16.663 0 7.385-5.87 12.497-13.066 12.497-8.142 0-13.443-7.952-13.443-15.716 0-13.822 8.331-26.509 19.124-34.083C56.615 6.38 57.562 6 58.509 6c.947 0 1.893.568 2.84 1.704l1.894 2.462c.568.568.757 1.136.757 1.893zm-36.166 0c0 .757-.189 1.514-1.136 2.271-3.787 3.787-7.763 9.09-7.763 13.633 0 5.681 7.574 9.847 7.574 16.663 0 7.385-5.68 12.497-12.876 12.497C5.491 57.124 0 49.172 0 41.408 0 27.586 8.331 14.9 19.124 7.325 20.45 6.38 21.396 6 22.344 6c.946 0 1.893.568 2.84 1.704l1.893 2.462c.568.568.757 1.136.757 1.893z"
    fill="#007CDF"
  />
);

export default iconQuote;
