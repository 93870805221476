import { palette } from '../palette/Palette';

const CompositeList = {
  outerContainerMd: {
    width: '120%'
  },
  compTitle: {
    '& $sectionTitleSmall': {
      color: palette.primary.second
    }
  },
  outerContainerSm: {
    width: '120%'
  },
  sectionTitleSmall: {
    margin: '0 0 20px'
  },
  containerOuter: {
    paddingRight: 40
  },
  containerOuterMd: {
    paddingRight: 30
  },
  containerOuterSm: {
    paddingRight: '20px'
  },
  containerInner: {
    boxSizing: 'border-box',
    width: 480,
    height: 458,
    padding: '40px 40px 25px',
    backgroundColor: palette.grey.first,
    position: 'relative',
    '& $title': {
      color: palette.primary.second
    }
  },
  containerInnerMd: {
    width: 400,
    height: 408
  },
  containerInnerSl: {
    maxWidth: 465,
    height: 360
  },
  containerInnerSm: {
    width: 320,
    height: 354
  },
  orderNumber: {
    color: palette.primary.first,
    paddingBottom: 32
  },
  title: {
    padding: '0 0 24px'
  },
  innerText: {
    padding: '0 0 40px',
    '& a': {
      color: palette.primary.first
    }
  },

  sectionTitleSmallMd: { maxWidth: '80%' },

  innerLink: {
    color: palette.secondary.first
  },
  sliderSm: {
    '& div[class=slick-track]': {
      marginLeft: 0,
      width: '50px !important'
    }
  },
  titleMd: {
    color: palette.primary.second
  },
  text: {
    paddingBottom: 40
  },
  textMd: {
    color: palette.default.black
  }
};

export default CompositeList;
