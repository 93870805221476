import { palette } from '../../palette/Palette';

const BackNavigation = {
  wrapper: {
    borderBottom: '2px solid #acaaaa'
  },

  backButton: {
    color: palette.primary.first,
    fontFamily: 'Sky-Bold',
    fontSize: 20,
    textTransform: 'none'
  },

  hoverColor: {
    color: '#0070a0'
  }
};

export default BackNavigation;
