import { palette } from '../palette/Palette';

const LoginOverlay = {
  outerHolder: {
    zIndex: 5,
    height: 400,
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 17%, ${palette.default.white} 45%, ${palette.default.white})`,
    '& $button': {
      minWidth: 110,
      minHeight: 52,
      textAlign: 'center',
      marginBottom: 0,
      marginRight: 16
    },
    '& $registerButton': {
      backgroundColor: palette.primary.first,
      border: `1px solid ${palette.primary.first}`,
      minWidth: 110,
      minHeight: 52,
      marginBottom: 0,
      '&:hover': {
        backgroundColor: palette.primary.second
      }
    }
  },

  textHolder: {
    padding: '0 40px',
    margin: '24px auto'
  },

  loginText: {
    textTransform: 'uppercase'
  },

  registerButton: {
    '& $buttonTextRegister': {
      color: `${palette.default.white} !important`
    }
  },
  buttonText: {
    textTransform: 'uppercase'
  },
  buttonTextRegister: {
    textTransform: 'uppercase'
  },

  iconsHolder: {
    '& svg': {
      width: 140,
      height: 30
    }
  },

  iconsHolderMd: {
    '& svg': {
      width: 142,
      height: 30
    }
  },

  iconsHolderSm: {
    '& svg': {
      width: 95,
      height: 20
    }
  },

  iconViewBox: '0 0 175 37',

  outerHolderMd: {
    paddingTop: 32,
    '& $button': {
      minWidth: 88,
      minHeight: 42
    },

    '& $registerButton': {
      minWidth: 90,
      minHeight: 42
    }
  },

  textHolderSm: {
    maxWidth: 311,
    padding: '0 16px',
    marginTop: 16
  },

  outerHolderSm: {
    height: 300,
    paddingTop: 24,
    '& $button': {
      minWidth: 76,
      minHeight: 32
    },
    '& $registerButton': {
      minWidth: 76,
      minHeight: 32
    }
  },
  textHolderMd: {
    maxWidth: 350,
    padding: '0 32px'
  }
};

export default LoginOverlay;
