import { palette } from '../../palette/Palette';

const PlayButton = {
  button: {
    color: palette.primary.first,
    width: 70,
    height: 70,
    hoverColor: palette.primary.second,

    '& svg': {
      width: 70,
      height: 70
    }
  },

  buttonMiniPlayer: {
    width: 45,
    height: 45,

    '& svg': {
      width: 45,
      height: 45
    }
  },

  pulseGradient: {
    first: `radial-gradient(50% 50% at 50% 50%, rgba(0, 124, 223, 0) 0%, rgba(0, 46, 223, 0) 61.46%, rgba(0, 124, 223, 0.486487) 70.31%, ${palette.primary.first} 100%)`,
    second: `radial-gradient(50% 50% at 50% 50%, rgba(0, 124, 223, 0) 0%, rgba(0, 46, 223, 0) 61.46%, rgba(0, 124, 223, 0.486487) 70.31%, ${palette.primary.first} 100%)`
  }
};

export default PlayButton;
