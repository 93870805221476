import { palette } from '../palette/Palette';

const UniversalVotingLinks = {
  link: {
    color: palette.secondary.first,
    transition: 'color 0.2s',
    '& svg': {
      fill: palette.secondary.first,
      transition: 'color 0.2s'
    },
    '&:hover': {
      color: palette.secondary.second,
      transition: 'fill 0.2s',
      '& svg': {
        fill: palette.secondary.second,
        transition: 'fill 0.2s'
      }
    }
  },
  linkTitle: {
    color: palette.default.white
  }
};

export default UniversalVotingLinks;
