import { palette } from '../palette/Palette';

const Frequency = {
  holder: {
    verticalAlign: 'middle'
  },

  description: {},

  content: {
    '& $description': {
      color: palette.default.black
    },
    verticalAlign: 'top',
    paddingRight: '1.6%'
  },

  freqImage: {
    verticalAlign: 'top',
    paddingLeft: '1.6%'
  },

  freqTitle: {},

  title: {
    '& $freqTitle': {
      color: palette.primary.second
    },
    margin: '0 0 20px'
  },

  inputOuter: {
    margin: '50px 0 0'
  },

  inputHolder: {
    maxWidth: '580px',
    border: `2px solid ${palette.grey.first}`
  },

  inputTypeText: {
    padding: '12px 40px 12px 16px',
    lineHeight: 'inherit',
    backgroundColor: palette.grey.first,
    color: palette.grey.third,
    '&:hover': {
      outline: `2px solid ${palette.grey.second}`
    },
    '&::placeholder': {
      color: palette.grey.third
    },
    '&::-ms-input-placeholder': {
      color: palette.grey.third
    },
    '&:-ms-input-placeholder': {
      color: palette.grey.third
    }
  },

  infoList: {
    list: {
      color: palette.primary.first
    },
    link: {
      color: palette.primary.first
    }
  },

  dropdownListHolder: {
    padding: '0 20px',
    marginTop: 10,
    zIndex: 9,
    backgroundColor: palette.default.white
  },

  dropdownList: {
    backgroundColor: palette.grey.first,
    border: `2px solid ${palette.grey.first}`
  },

  freqItem: {
    '&:hover': {
      color: palette.default.white
    },
    padding: '12px 0 12px 16px'
  },

  dropdownItem: {
    '& $freqItem': {
      color: palette.grey.third
    },
    color: palette.grey.second,
    margin: 8,
    '&:hover': {
      backgroundColor: palette.primary.first
    }
  },

  zipResults: {
    marginLeft: 2,
    padding: '40px 0 0'
  },

  locationTypography: {},

  location: {
    verticalAlign: 'bottom'
  },

  locationHolder: {
    '& $locationTypography': {
      color: palette.default.black
    }
  },

  locationIcon: {
    verticalAlign: 'middle'
  },

  stationLocation: {},

  locationText: {
    '& $stationLocation': {
      color: palette.primary.second
    },
    verticalAlign: 'middle',
    paddingLeft: '31px'
  },

  stationFrequency: {},

  frequency: {
    '& $stationFrequency': {
      color: palette.primary.second
    },
    textAlign: 'right',
    verticalAlign: 'bottom'
  },

  clearText: {},

  searchClear: {
    '& $clearText': {
      color: palette.primary.first
    },
    right: 20
  },

  iconSvg: {
    transform: 'translateY(-50%)'
  },

  searchIcon: {
    '& $iconSvg': {
      fill: palette.primary.first
    },
    right: 0,
    top: 0,
    width: '72px',
    height: '50px'
  },

  tableBottom: {
    marginTop: '100px'
  },

  infoTitle: {
    padding: '0 0 10px'
  },

  infoItemTitle: {
    color: palette.primary.second
  },

  infoItem: {
    verticalAlign: 'top',
    paddingRight: '1.6%'
  },

  linkText: {
    '& $textLink': {
      color: palette.primary.first
    },
    padding: '20px 2px 0',
    '&:hover': {
      '& $textLink': {
        color: palette.primary.third
      },
      '& svg': {
        fill: palette.primary.third
      }
    }
  },

  svgLink: {
    float: 'right',
    paddingLeft: '7px',
    top: 8
  },

  linkIcon: {
    width: 28,
    height: 28
  },

  textLink: {},

  contentMd: {
    padding: 0
  },

  inputOuterMd: {
    margin: '40px 0 0'
  },

  zipResultsSm: {
    width: '98%',
    padding: '30px 0 0'
  },

  searchIconMd: {
    paddingLeft: '25px'
  },

  tableBottomMd: {
    marginTop: 100
  },

  titleSm: {
    margin: '0 0 20px'
  },

  tableBottomSm: {
    marginTop: '60px'
  },

  infoItemSm: {
    padding: 0,
    marginLeft: 2,
    paddingBottom: 45
  },

  pinIcon: {
    width: 24,
    height: 24
  },

  searchSvg: {
    width: 23,
    height: 24
  },
  noResultText: {
    paddingLeft: 6
  }
};

export default Frequency;
