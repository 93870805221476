import { palette } from '../palette/Palette';

const Typography = {
  colorPrimary: {
    color: palette.primary.first
  },

  colorHeading: {
    color: palette.primary.first
  },

  colorText: {
    color: palette.grey.third
  },

  colorLight: {
    color: palette.default.white
  },

  colorGrey: {
    color: palette.grey.third
  },

  colorSecond: {
    color: palette.primary.first
  },

  colorNavy: {
    color: palette.default.black
  },

  colorDark: {
    color: palette.default.black
  },

  colorGreylight: {
    color: palette.grey.first
  },

  colorGreymain: {
    color: palette.grey.second
  },

  colorGreydark: {
    color: palette.grey.third
  },

  colorTextdark: {
    color: palette.grey.second
  },

  colorTitleLight: {
    color: palette.grey.third
  },

  colorNavyBlue: {
    color: palette.primary.second
  }
};

export default Typography;
