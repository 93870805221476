import { palette } from '../palette/Palette';

const UniversalVotingMainScreen = {
  tabLinkText: {
    color: palette.default.white
  },

  lineSeparator: {
    borderBottom: `2px solid ${palette.grey.fourth}`
  },

  activeSm: {
    paddingBottom: 14
  },

  selectionIndicator: {
    backgroundColor: palette.primary.first,
    color: palette.default.white
  },

  userListFixedHeader: {
    backgroundColor: palette.grey.second
  }
};

export default UniversalVotingMainScreen;
