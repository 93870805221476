import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconMiniPlayerChevron = (
  <>
    <defs>
      <circle id="28wjh4plva" cx="32" cy="32" r="32" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-0.000000, 0.000000) translate(32.000000, 32.000000) rotate(-90.000000) translate(-32.000000, -32.000000)">
            <circle cx="32" cy="32" r="32" />
            <circle cx="32" cy="32" r="32" fill={palette.grey.first} />
          </g>
          <g fill={palette.grey.second}>
            <path
              d="M9.867 29.787l-3.091-3.093c-.285-.282-.285-.744 0-1.03l10.149-10.149c.285-.284.285-.746 0-1.03L6.775 4.335c-.284-.285-.284-.747 0-1.03L9.868.214c.284-.284.746-.284 1.03 0l14.272 14.272c.284.284.284.746 0 1.03L10.897 29.787c-.284.284-.746.284-1.03 0z"
              transform="translate(-0.000000, 0.000000) translate(32.000000, 32.000000) rotate(-90.000000) translate(-32.000000, -32.000000) translate(17.000000, 17.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </>
);

export default iconMiniPlayerChevron;
