import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconCheckmark = (
  <path
    d="M63.23 15.945 23.865 55.309a.886.886 0 0 1-1.254 0L.259 32.957a.886.886 0 0 1 0-1.253l5.432-5.431a.887.887 0 0 1 1.254 0l16.294 16.293L56.545 9.26a.887.887 0 0 1 1.254 0l5.43 5.432a.886.886 0 0 1 0 1.254z"
    fill={palette.primary.first}
  />
);

export default iconCheckmark;
