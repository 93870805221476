import { palette } from '../palette/Palette';

const Button = {
  default: {
    minWidth: 112,
    minHeight: 52,
    padding: '16px 24px',
    border: 'none',
    backgroundColor: palette.secondary.first,
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: palette.secondary.first
      }
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: palette.secondary.second
      }
    },
    '& *:first-child': {
      color: palette.primary.third
    }
  },

  disabled: {
    opacity: 0.3
  },

  textFrameButton: {
    minHeight: 44,
    border: 'none',
    color: palette.default.white,
    backgroundColor: palette.secondary.first,
    '&:hover': {
      backgroundColor: palette.secondary.second,
      cursor: 'pointer'
    },
    '& *:first-child': {
      color: palette.primary.third
    }
  },

  imageSectionButton: {
    fontSize: '1em',
    minWidth: 128,
    minHeight: 52,
    padding: '2px 20px 0 20px',
    boxSizing: 'border-box',
    textTransform: 'uppercase',
    backgroundColor: palette.secondary.first,
    outline: 'none',
    border: 'none',
    objectFit: 'contain',
    '& *:first-child': {
      color: palette.primary.third
    },
    '&:hover': {
      backgroundColor: palette.secondary.second
    }
  },

  registerModalButton: {
    backgroundColor: 'transparent',
    border: `2px solid ${palette.primary.first}`,

    '& p': {
      color: `${palette.primary.first}!important`
    },

    '&:hover': {
      backgroundColor: 'transparent',
      border: `2px solid ${palette.primary.second}`,
      '& p': {
        color: `${palette.primary.second}!important`
      }
    }
  },

  imageSectionButtonSl: {
    minWidth: 104,
    minHeight: 42
  },

  imageSectionButtonMd: {
    margin: '15px 0'
  },

  imageSectionButtonSm: {
    margin: 0,
    minWidth: 88,
    minHeight: 32
  },

  defaultMd: {
    minWidth: 89,
    minHeight: 42,
    padding: '12px 16px'
  },

  defaultSm: {
    minWidth: 75,
    minHeight: 32,
    padding: '8px 12px'
  },

  votingAddTrackBtn: {
    color: palette.primary.third,
    backgroundColor: palette.secondary.first,
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: palette.secondary.first
      }
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: palette.secondary.second
      }
    }
  }
};

export default Button;
