import React from 'react';

const iconChevronDown = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M58.2878 23.0841C58.793 23.5894 58.793 24.4106 58.2878 24.9159L32.9159 50.2878C32.4106 50.793 31.5894 50.793 31.0841 50.2878L5.71224 24.9159C5.20703 24.4106 5.20703 23.5894 5.71224 23.0841L11.21 17.589C11.7126 17.0838 12.5339 17.0838 13.0417 17.589L31.0841 35.6315C31.5894 36.1393 32.4106 36.1393 32.9159 35.6315L50.9583 17.589C51.4661 17.0838 52.2874 17.0838 52.79 17.589L58.2878 23.0841Z"
  />
);

export default iconChevronDown;
