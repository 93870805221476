import { palette } from '../palette/Palette';

const UniversalVotingOptin = {
  textDiv: {
    '& a': {
      color: palette.secondary.first,
      '&:hover': {
        color: palette.secondary.second
      }
    }
  }
};

export default UniversalVotingOptin;
