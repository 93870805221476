import React from 'react';

const universalVotingIconPause = (
  <g
    id="assets/station-no-cover-Sky-Radio-1"
    transform="translate(-40.000000, -41.000000)"
    fill="#FFFFFF"
  >
    <g
      id="sky-radio-/-icons-/-general-/-pause"
      transform="translate(22.000000, 22.000000)"
    >
      <path
        d="M50.4,19.8 L43.2,19.8 C41.2146,19.8 39.6,21.4146 39.6,23.4 L39.6,48.6 C39.6,50.5854 41.2146,52.2 43.2,52.2 L50.4,52.2 C52.3854,52.2 54,50.5854 54,48.6 L54,23.4 C54,21.4146 52.3854,19.8 50.4,19.8 L50.4,19.8 Z M28.8,19.8 C30.7854,19.8 32.4,21.4146 32.4,23.4 L32.4,23.4 L32.4,48.6 C32.4,50.5854 30.7854,52.2 28.8,52.2 L28.8,52.2 L21.6,52.2 C19.6146,52.2 18,50.5854 18,48.6 L18,48.6 L18,23.4 C18,21.4146 19.6146,19.8 21.6,19.8 L21.6,19.8 Z M50.4,23.4 L50.4018,48.6 L43.2,48.6 L43.2,23.4 L50.4,23.4 Z M28.8,23.4 L21.6,23.4 L21.6,48.6 L28.8018,48.6 L28.8,23.4 Z"
        id="Combined-Shape"
      />
    </g>
  </g>
);

export default universalVotingIconPause;
