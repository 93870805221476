import { palette } from '../../palette/Palette';

const MiniPlayer = {
  title: {
    fontFamily: 'Sky-Bold',
    fontSize: 12
  },

  subTitle: {
    color: '#706f6f',
    fontFamily: 'Sky-Regular',
    fontSize: 10
  },

  miniPlayerWrapper: {
    backgroundColor: palette.default.black
  },
  rankingPosition: {
    backgroundColor: palette.secondary.first
  },
  rankingPositionText: {
    color: palette.primary.third
  },
  category: {
    backgroundColor: palette.secondary.first,
    color: palette.primary.third
  }
};

export default MiniPlayer;
