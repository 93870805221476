import { palette } from '../../palette/Palette';

const Dialog = {
  content: {
    color: '#706f6f',
    background: '#fafafa',
    boxShadow: '0px 0px 32px -5px rgba(0,0,0,.25)'
  },

  fontPargraph: {
    fontSize: 16,
    fontFamily: 'Sky-Bold'
  },

  hrColor: {
    color: '#cccccc',
    backgroundColor: '#cccccc'
  },

  hrBefore: {
    backgroundColor: '#ff3750',
    border: '6px solid #fafafa'
  },

  title: {
    color: palette.grey.second,
    fontFamily: 'Sky-Bold',
    fontSize: 16
  }
};

export default Dialog;
