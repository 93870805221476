import React from 'react';

const iconPauseOnButton = (
  <path
    d="M26.696 0v64H10V0h16.696zm27.826 0v64H37.826V0h16.696z"
    fill="#FFF"
  />
);

export default iconPauseOnButton;
