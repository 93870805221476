import { palette } from '../palette/Palette';

const SvgIcon = {
  root: {
    fill: palette.grey.first
  },

  colorPrimary: {
    fill: palette.default.white
  },

  colorSecondary: {
    fill: palette.primary.first
  },

  colorDark: {
    fill: palette.primary.first
  },

  colorBlack: {
    fill: palette.default.black
  },

  colorLink: {
    fill: palette.primary.first
  }
};

export default SvgIcon;
