import { palette } from '../palette/Palette';

const CountdownDisplay = {
  countdownWrapper: {
    color: palette.default.black
  },

  rectangle: {
    backgroundColor: palette.primary.first,
    color: palette.default.white
  }
};

export default CountdownDisplay;
