import { palette } from '../palette/Palette';

const NotificationPopup = {
  wrapper: {
    background: palette.default.white,
    boxShadow: '0 23px 52px 0 rgba(0, 0, 0, 0.4)'
  },

  buttonDisagree: {
    background: palette.grey.fifth,
    color: palette.default.white,
    textTransform: 'uppercase'
  },

  buttonAgree: {
    background: palette.secondary.first,
    color: palette.primary.third,
    textTransform: 'uppercase'
  },

  svgLogo: {
    width: 151,
    height: 71,
    viewBox: '0 0 151 71'
  },

  svgLogoSm: {
    width: 104,
    height: 60,
    viewBox: '0 0 104 60'
  },

  svgClose: {
    width: 19,
    height: 19,
    fill: palette.grey.second
  },

  svgCloseSm: {
    width: 13,
    height: 13
  },

  svgTriangle: {
    width: 67,
    height: 31,
    viewBox: '0 0 67 31'
  },

  svgTriangleMd: {
    width: 45,
    height: 21
  },

  svgTriangleSm: {
    width: 32,
    height: 15
  },
  svgLogoMd: {
    width: 172,
    height: 60
  },

  svgCloseMd: {
    width: 17,
    height: 17
  }
};

export default NotificationPopup;
