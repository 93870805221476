import { palette } from '../../palette/Palette';

const PlaylistModal = {
  title: {
    fontFamily: 'Sky-Bold',
    fontSize: 17,
    lineHeight: 1.65,
    color: palette.primary.third
  },
  titleSpan: {
    backgroundColor: palette.default.white
  },
  modalContent: {
    padding: '16px 30px'
  },
  modalContentMd: {
    padding: '16px 20px'
  }
};

export default PlaylistModal;
