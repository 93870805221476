import { palette } from '../palette/Palette';

const OverviewSkeleton = {
  greyBox: {
    backgroundColor: palette.grey.first
  },

  title: {
    backgroundColor: palette.grey.fifth
  },

  headerHolder: {
    width: '100%',
    maxWidth: '100%'
  },

  header: {
    height: 506
  },

  headerMd: {
    height: 302
  },

  headerSm: {
    height: 476
  }
};

export default OverviewSkeleton;
