import { palette } from '../../palette/Palette';

const Tooltip = {
  tooltipWrapper: {
    backgroundColor: '#ff3750',
    fontFamily: 'Sky-Bold',
    fontSize: 14,
    lineHeight: 1,
    color: palette.default.white
  },

  tooltipWrapperBefore: {
    borderTopColor: '#ff3750'
  },

  tooltipWrapperSm: {
    backgroundColor: palette.secondary.first,
    color: palette.primary.second,
    textTransform: 'uppercase',
    padding: '4px 8px'
  },

  tooltipWrapperBeforeSm: {
    borderTopColor: palette.secondary.first
  }
};

export default Tooltip;
