import { palette } from '../../palette/Palette';

const AudioVideoToggle = {
  toggleButton: {
    fontFamily: 'Sky-Bold',
    fontSize: 18,
    textTransform: 'none',
    color: palette.primary.first,
    hoverColor: palette.primary.second
  }
};

export default AudioVideoToggle;
