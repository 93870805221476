import { palette } from '../palette/Palette';

const ProgramSlide = {
  containerOuter: {
    paddingRight: '20px'
  },
  containerInner: {
    height: '210px',
    width: '320px',
    backgroundColor: palette.primary.first,
    cursor: 'default'
  },
  containerOuterActive: {
    paddingRight: '20px',
    containerInner: {
      transition: 'background-color 0.15s ease-in-out'
    }
  },
  containerTableCell: {
    padding: '0 5%'
  },
  showName: {
    color: palette.default.white,
    margin: '0 0 38px',
    textTransform: 'uppercase'
  },
  onAirBadge: {
    padding: '5px 8px',
    width: '50px',
    heigth: '14px',
    textTransform: 'uppercase'
  },
  onAirBadgeMd: {
    width: '45px',
    heigth: '13px',
    padding: '4px 6px'
  },
  timeStart: {
    color: palette.default.white,
    right: 'calc(100% + 20px)',
    bottom: '25px'
  },
  timeEnd: {
    color: palette.default.white,
    left: 'calc(100% + 20px)',
    bottom: '25px'
  },
  isLiveBackground: {
    backgroundColor: palette.primary.first
  },
  graphicWrapper: {
    margin: 0,
    maxWidth: '60%',
    width: 'auto',
    display: 'inline-block'
  },
  graphicLeft: {
    backgroundColor: palette.primary.first,
    display: 'block',
    left: 'calc(100% + 10px)',
    bottom: 48,
    width: 1000,
    height: 3,
    before: {
      borderTopColor: palette.primary.first,
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
      top: '-10px',
      left: 0,
      width: 0,
      height: 0,
      borderWidth: '5px 3px 0 3px'
    }
  },
  graphicRight: {
    backgroundColor: palette.primary.first,
    display: 'block',
    right: 'calc(100% + 10px)',
    bottom: 48,
    width: 1000,
    height: 3,
    before: {
      borderTopColor: palette.primary.first,
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
      top: '-10px',
      right: 0,
      width: 0,
      height: 0,
      borderWidth: '5px 3px 0 3px'
    }
  },
  containerOuterMd: {
    padding: '0 10px'
  },
  containerInnerMd: {
    height: '160px',
    width: '284px'
  },
  containerOuterActiveMd: {
    padding: '0 10px'
  },
  containerInnerSm: {
    height: '180px',
    width: '280px'
  },
  containerInnerXs: {
    height: '158px',
    width: '245px'
  },
  containerTableCellXs: {
    padding: '0 3%'
  },
  timeStartSm: {
    left: 5,
    top: 40
  },
  timeEndSm: {
    right: 5,
    top: 40
  },
  daySliderBackground: {
    width: 240,
    backgroundColor: palette.primary.second,
    backgroundImage: 'none'
  },
  daySliderBackgroundMd: {
    width: 200
  }
};

export default ProgramSlide;
