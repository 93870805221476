import { palette } from '../palette/Palette';

const UniversalVotingUserTelephone = {
  telephoneConsentText: {
    color: palette.grey.eight
  },

  telephoneInfoText: {
    '& a': {
      color: palette.secondary.first,
      '&:hover': {
        color: palette.secondary.second
      }
    }
  },

  inputBox: {
    '& p': {
      color: palette.default.white
    }
  },

  inputTypeText: {
    borderColor: palette.grey.first
  }
};

export default UniversalVotingUserTelephone;
