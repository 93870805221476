import { palette } from '../palette/Palette';

const UniversalVotingUserListTrack = {
  track: {
    backgroundColor: palette.grey.sixth,
    borderColor: palette.grey.seventh,
    '& span': {
      color: palette.grey.first
    }
  },

  text: {
    color: palette.grey.eight
  },
  votedTrack: {
    backgroundColor: palette.grey.seventh,
    borderColor: palette.grey.eight
  },

  dragged: {
    borderColor: palette.secondary.first
  },

  hightlightedNumber: {
    color: palette.default.white
  },

  orderNumber: {
    color: palette.default.white
  },

  artist: {
    color: palette.grey.fourth
  },

  title: {
    color: palette.grey.fourth
  },

  removeIconSvg: {
    width: 16,
    height: 16,
    fill: palette.default.white
  },

  trackBorderDragging: {
    borderLeftColor: palette.primary.first
  },

  dragDropIcon: {
    fill: palette.secondary.first
  },

  mobileDragDropColor: {
    fill: palette.secondary.first
  }
};

export default UniversalVotingUserListTrack;
