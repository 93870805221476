import { palette } from '../palette/Palette';

const HeaderSectionSlide = {
  contentWrapper: {
    width: '100%',
    maxWidth: 'none'
  },
  contentWrapperLg: {
    width: '100%',
    maxWidth: 'none'
  },

  textContentHolder: {
    paddingLeft: '15%'
  },

  textContentHolderLg: {
    paddingLeft: '13%'
  },

  textContentHolderMl: {
    paddingLeft: '11%'
  },
  rootSm: {
    height: 540
  },

  dark: {
    background: palette.primary.third,
    color: palette.default.white,
    '& $countdown': {
      '& span': {
        color: palette.default.white
      },
      '& h2': {
        color: palette.default.white
      }
    },
    '& $a': {
      color: palette.secondary.first,
      '@media (hover: hover)': {
        '&:hover': {
          color: palette.secondary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          color: palette.secondary.second
        }
      }
    },
    '& $buttonPrimary': {
      color: palette.primary.third,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: palette.secondary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          backgroundColor: palette.secondary.second
        }
      }
    },
    '& $buttonSecondary': {
      border: `solid 2px ${palette.secondary.first}`,
      background: 'transparent',
      '& p': {
        color: palette.secondary.first
      },
      '@media (hover: hover)': {
        '&:hover': {
          borderColor: palette.secondary.second,
          '& p': {
            color: palette.secondary.second
          }
        }
      },
      '@media (hover: none)': {
        '&:active': {
          borderColor: palette.primary.third,
          '& p': {
            color: palette.secondary.second
          }
        }
      }
    }
  },

  light: {
    background: palette.grey.fourth,
    color: palette.primary.third,
    '& $countdown': {
      '& span': {
        color: palette.default.black
      }
    },
    '& $a': {
      color: palette.primary.first,
      '@media (hover: hover)': {
        '&:hover': {
          color: palette.primary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          color: palette.primary.second
        }
      }
    },
    '& $buttonPrimary': {
      color: palette.primary.third,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: palette.secondary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          backgroundColor: palette.secondary.second
        }
      }
    },
    '& $buttonSecondary': {
      border: `solid 2px ${palette.secondary.first}`,
      background: palette.grey.fourth,
      '& p': {
        color: palette.primary.first
      },
      '@media (hover: hover)': {
        '&:hover': {
          borderColor: palette.secondary.second,
          '& p': {
            color: palette.primary.second
          }
        }
      },
      '@media (hover: none)': {
        '&:active': {
          borderColor: palette.grey.fourth,
          '& p': {
            color: palette.primary.second
          }
        }
      }
    }
  },

  color: {
    background: palette.primary.second,
    color: palette.default.white,

    '& $countdown': {
      '& span': {
        color: palette.default.white
      },
      '& h2': {
        color: palette.default.white
      }
    },

    '& $a': {
      color: palette.secondary.first,
      '@media (hover: hover)': {
        '&:hover': {
          color: palette.secondary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          color: palette.secondary.second
        }
      }
    },
    '& $buttonPrimary': {
      color: palette.primary.third,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: palette.secondary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          backgroundColor: palette.secondary.second
        }
      }
    },
    '& $buttonSecondary': {
      border: `solid 2px ${palette.secondary.first}`,
      background: 'transparent',
      '& p': {
        color: palette.secondary.first
      },
      '@media (hover: hover)': {
        '&:hover': {
          borderColor: palette.secondary.second,
          '& p': {
            color: palette.secondary.second
          }
        }
      },
      '@media (hover: none)': {
        '&:active': {
          borderColor: palette.primary.second,
          '& p': {
            color: palette.secondary.second
          }
        }
      }
    }
  },

  special: {
    background: palette.primary.second,
    color: palette.default.white,

    '& $countdown': {
      '& span': {
        color: palette.default.white
      },
      '& h2': {
        color: palette.default.white
      }
    },

    '& $a': {
      color: palette.secondary.first,
      '@media (hover: hover)': {
        '&:hover': {
          color: palette.secondary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          color: palette.secondary.second
        }
      }
    },
    '& $buttonPrimary': {
      color: palette.primary.third,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: palette.secondary.second
        }
      },
      '@media (hover: none)': {
        '&:active': {
          backgroundColor: palette.secondary.second
        }
      }
    },
    '& $buttonSecondary': {
      border: `solid 2px ${palette.secondary.first}`,
      background: 'transparent',
      '& p': {
        color: palette.secondary.first
      },
      '@media (hover: hover)': {
        '&:hover': {
          borderColor: palette.secondary.second,
          '& p': {
            color: palette.secondary.second
          }
        }
      },
      '@media (hover: none)': {
        '&:active': {
          borderColor: palette.primary.second,
          '& p': {
            color: palette.secondary.second
          }
        }
      }
    }
  },

  darkMd: {
    '& $buttonSecondary': {
      '@media (hover: hover)': {
        '&:hover': {
          borderColor: `${palette.secondary.first} !important`
        }
      },
      '@media (hover: none)': {
        '&:active': {
          borderColor: `${palette.secondary.first} !important`
        }
      }
    }
  },

  colorMd: {
    '& $buttonSecondary': {
      '@media (hover: hover)': {
        '&:hover': {
          borderColor: `${palette.secondary.first} !important`
        }
      },
      '@media (hover: none)': {
        '&:active': {
          borderColor: `${palette.secondary.first} !important`
        }
      }
    }
  },

  lightMd: {
    '& $buttonSecondary': {
      border: `solid 2px ${palette.primary.second}`,
      '@media (hover: hover)': {
        '&:hover': {
          borderColor: `${palette.primary.second} !important`
        }
      },
      '@media (hover: none)': {
        '&:active': {
          borderColor: `${palette.primary.second} !important`
        }
      }
    }
  },

  imageOverlaySvg: {
    left: -2,
    '& g': {
      '& g': {
        '& g': {
          '& path:first-child': {
            fill: palette.primary.second
          }
        }
      }
    }
  },

  buttonsHolderMd: {
    '& button': {
      minHeight: 48
    }
  },

  buttonsHolderSm: {
    '& button': {
      minHeight: 44
    }
  },

  buttonCommon: {
    color: palette.default.white
  },

  iconPlayOnButton: {
    width: 20,
    height: 24,
    '& path': {
      fill: palette.primary.third
    }
  },

  iconPlayOnButtonMd: {
    width: 16,
    height: 20
  },

  iconPlayOnButtonSm: {
    width: 13,
    height: 16
  },

  buttonPrimaryMd: {
    // because of hover on default button element
    '&:hover': {
      background: palette.secondary.first
    },
    '&:active': {
      background: palette.secondary.first
    }
  },

  mask: {
    position: 'absolute',
    maxWidth: 390,
    height: '50%'
  },

  maskMd: {
    height: 280,
    maxWidth: 280
  },

  image: {
    height: 480,
    width: '100%',
    objectFit: 'cover'
  },

  imageSm: {
    objectFit: 'unset',
    height: 'auto'
  }
};

export default HeaderSectionSlide;
