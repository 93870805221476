import { palette } from '../palette/Palette';

const SwimlaneSection = {
  sliderTitle: {
    color: palette.primary.second
  },

  buttonControlSvg: {
    '&:hover': {
      '& g circle:last-of-type': {
        fill: palette.primary.second
      },
      '& rect': {
        fill: palette.primary.second
      }
    }
  },

  overlayRight: {
    background: 'linear-gradient(to left, #fff 0%, rgba(255, 255, 255, 0) 100%)'
  }
};

export default SwimlaneSection;
