import { palette } from '../palette/Palette';

const SearchPageCategories = {
  buttonControlSvgHover: {
    '& g circle:last-of-type': {
      fill: palette.primary.fourth
    }
  }
};

export default SearchPageCategories;
