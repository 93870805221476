import { generateTheme } from '@talpanetwork/talpa-publishing-theme/theming/generateTheme';

import * as iconSet from '../icons/index';

import * as styles from './styles';
import { palette } from './palette/Palette';

const fontFallback = 'sans-serif';

const SkyRegular = `'Sky-Regular', ${fontFallback}`;
const SkyBold = `'Sky-bold', ${fontFallback}`;

const defaultTheme = generateTheme();
const fontSize = 16;

export function pxToEm(value) {
  return `${value / fontSize}em`;
}

export const styleOptions = generateTheme({
  palette: {
    ...palette
  },

  icons: {
    ...iconSet,
    defaultFill: '#0091FF'
  },

  shape: {
    noBorderRadius: 0,
    borderRadius: 2
  },

  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },

  components: {
    ...styles
  },

  spacing: {
    unit: 10
  },

  typography: {
    fontFamilyHeavy: SkyBold,
    fontFamilyBold: SkyBold,
    fontFamilyRegular: SkyRegular,
    fontFamilyBoldAlt1: SkyBold,
    fontFamilyHeavyAlt1: SkyBold,

    headerLarge: {
      fontSize: pxToEm(21),
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(20),
        lineHeight: '26px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(18),
        lineHeight: '24px'
      }
    },

    headerMedium: {
      fontSize: pxToEm(23),
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(22),
        lineHeight: '26px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(20),
        lineHeight: '24px'
      }
    },

    frequencySearchClearText: {
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(10),
        lineHeight: '16px'
      }
    },

    headerSmall: {
      fontSize: pxToEm(15),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(12),
        lineHeight: '16px'
      }
    },

    articleHeadline: {
      fontSize: pxToEm(29),
      lineHeight: '32px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(24),
        lineHeight: '27px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(18),
        lineHeight: '22px'
      }
    },

    campaignHeader: {
      fontSize: pxToEm(17),
      lineHeight: '17px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(15),
        lineHeight: '15px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(13),
        lineHeight: '13px'
      }
    },

    notFoundTitle: {
      fontSize: pxToEm(23),
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(22),
        lineHeight: '26px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(20),
        lineHeight: '24px'
      }
    },

    headlineLarge: {
      fontSize: pxToEm(23),
      lineHeight: '30px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(18),
        lineHeight: '24px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(15),
        lineHeight: '20px'
      }
    },

    loginProfileName: {
      fontSize: pxToEm(19),
      lineHeight: '19px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(17),
        lineHeight: '17px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(15),
        lineHeight: '15px'
      }
    },

    headlineMedium: {
      fontSize: pxToEm(17),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(15),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(13),
        lineHeight: '16px'
      }
    },

    headlineMediumRelatedArticle: {
      fontFamily: 'Sky-Bold',
      fontSize: pxToEm(17),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(15),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(13),
        lineHeight: '16px'
      }
    },

    headlineSmall: {
      fontSize: pxToEm(13),
      lineHeight: '13px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(11),
        lineHeight: '11px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(9),
        lineHeight: '9px'
      }
    },

    buttonCta: {
      fontFamily: SkyBold,
      fontSize: pxToEm(19),
      lineHeight: '19px',
      textTransform: 'uppercase',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(17),
        lineHeight: '17px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(15),
        lineHeight: '15px'
      }
    },

    titleMedium: {
      fontSize: pxToEm(21),
      lineHeight: '24px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(17),
        lineHeight: '20px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(14),
        lineHeight: '18px'
      }
    },

    videoListTitle: {
      fontSize: pxToEm(23),
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(22),
        lineHeight: '26px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(20),
        lineHeight: '24px'
      }
    },

    signatureHeadlineLarge: {
      fontSize: pxToEm(28),
      lineHeight: '38px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(24),
        lineHeight: '32px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(18),
        lineHeight: '24px'
      }
    },

    signatureInitial: {
      fontSize: pxToEm(38),
      lineHeight: '38px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(32),
        lineHeight: '32px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(24),
        lineHeight: '24px'
      }
    },

    signatureHeadlineMedium: {
      fontSize: pxToEm(19),
      lineHeight: '24px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(16),
        lineHeight: '20px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(14),
        lineHeight: '18px'
      }
    },

    tag: {
      fontSize: pxToEm(14),
      lineHeight: '14px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(12),
        lineHeight: '12px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(10),
        lineHeight: '10px'
      }
    },

    infoMedium: {
      fontSize: pxToEm(22),
      lineHeight: '24px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(18),
        lineHeight: '20px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(16),
        lineHeight: '18px'
      }
    },

    paragraph: {
      fontSize: pxToEm(18),
      lineHeight: '30px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(16),
        lineHeight: '28px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(14),
        lineHeight: '24px'
      }
    },

    paragraphBold: {
      fontSize: pxToEm(18),
      lineHeight: '30px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(16),
        lineHeight: '28px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(14),
        lineHeight: '24px'
      }
    },

    infoSmall: {
      fontSize: pxToEm(13),
      lineHeight: '14px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(11),
        lineHeight: '12px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(9),
        lineHeight: '10px'
      }
    },

    inputMedium: {
      fontSize: pxToEm(27),
      lineHeight: '30px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(22),
        lineHeight: '24px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(18),
        lineHeight: '20px'
      }
    },

    inputSmall: {
      fontSize: pxToEm(16),
      lineHeight: '16px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '14px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(12),
        lineHeight: '12px'
      }
    },

    caption: {
      fontSize: pxToEm(14),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(12),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(10),
        lineHeight: '16px'
      }
    },

    labelLarge: {
      fontSize: pxToEm(17),
      lineHeight: '23px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(16),
        lineHeight: '21px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(15),
        lineHeight: '21px'
      }
    },

    labelMedium: {
      fontSize: pxToEm(14),
      lineHeight: '16px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(12),
        lineHeight: '14px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(10),
        lineHeight: '12px'
      }
    },

    labelSmall: {
      fontSize: pxToEm(11),
      lineHeight: '14px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(9),
        lineHeight: '12px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(8),
        lineHeight: '10px'
      }
    },

    link: {
      fontSize: pxToEm(18),
      lineHeight: '20px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(16),
        lineHeight: '18px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(14),
        lineHeight: '16px'
      }
    },

    linkSmall: {
      fontSize: pxToEm(12),
      lineHeight: '14px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(10),
        lineHeight: '12px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(8),
        lineHeight: '10px'
      }
    },

    trackRegular: {
      fontSize: pxToEm(13),
      lineHeight: '16px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(11),
        lineHeight: '14px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(10),
        lineHeight: '12px'
      }
    },

    trackBold: {
      fontSize: pxToEm(16),
      lineHeight: '18px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '16px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(12),
        lineHeight: '14px'
      }
    },

    contactItem: {
      fontSize: pxToEm(16),
      lineHeight: '16px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '14px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(12),
        lineHeight: '12px'
      }
    },

    date: {
      fontSize: pxToEm(14),
      lineHeight: '16px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(12),
        lineHeight: '14px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(11),
        lineHeight: '12px'
      }
    },

    dateClip: {
      fontSize: pxToEm(19),
      lineHeight: '19px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(17),
        lineHeight: '17px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(15),
        lineHeight: '15px'
      }
    },

    quote: {
      fontSize: pxToEm(24),
      lineHeight: '30px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(20),
        lineHeight: '24px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(16),
        lineHeight: '20px'
      }
    },

    stationTitle: {
      fontFamily: SkyBold,
      fontSize: pxToEm(18),
      lineHeight: '18px',

      [defaultTheme.breakpoints.down('ml')]: {
        fontSize: pxToEm(12),
        lineHeight: '12px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(9),
        lineHeight: '9px'
      }
    }
  },

  utils: {
    grid: {
      width: {
        default: '83.71%',
        large: '88%',
        medium: '92.2%',
        small: '100%'
      },

      maxWidth: {
        default: '1280px',
        large: '1067px',
        medium: '944px',
        small: '100%'
      }
    },

    smallGrid: {
      width: 832
    }
  }
});

export const generateThemeWithDeviceType = (deviceType) => {
  return generateTheme(styleOptions, deviceType);
};

export const theme = generateTheme(styleOptions);

export default theme;
