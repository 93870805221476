import { palette } from '../palette/Palette';

const Arrow = {
  rightArrowBackground: {
    backgroundColor: palette.default.white,
    width: 40
  },

  leftArrowBackground: {
    backgroundColor: palette.default.white,
    width: 40
  },

  arrowHolder: {
    backgroundColor: palette.primary.first,
    '&:hover': {
      backgroundColor: palette.primary.fourth
    }
  },

  compositeArrowBackgroundRight: {
    background: `linear-gradient(to right, rgba(255, 255, 255, 0), ${palette.default.white});`
  },

  compositeArrowBackgroundLeft: {
    background: `linear-gradient(to left, rgba(255, 255, 255, 0), ${palette.default.white});`
  }
};

export default Arrow;
