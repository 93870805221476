import { palette } from '../palette/Palette';

const Clips = {
  linkIcon: {
    width: 40,
    height: 18,
    transform: 'translateY(0)',
    '& path': {
      fill: palette.primary.first
    }
  },

  textWrapper: {
    padding: '0 0 23px'
  },

  textWrapperMd: {
    padding: '0 0 18px'
  },

  textWrapperSm: {
    padding: '0 0 23px'
  },

  seeMoreSm: {
    display: 'flex',
    alignItems: 'self-end'
  },
  sectionTitle: {
    color: palette.primary.second
  },

  seeMore: {
    color: palette.secondary.second,

    '& $linkIcon': {
      fill: palette.secondary.second
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default Clips;
