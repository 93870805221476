import { palette } from '@theme/palette/Palette';

const OverlayPlayButton = {
  button: {
    height: 76,
    width: 76,
    backgroundColor: palette.primary.first
  },

  hover: {
    height: 18,
    width: 18
  },

  lastListened: {
    height: 40,
    width: 40,
    backgroundColor: palette.secondary.first
  }
};

export default OverlayPlayButton;
