const Newsletter = {
  button: {
    width: '100%',
    borderWidth: '2px',
    padding: '8px 0',
    height: 50,
    minHeight: 50
  },

  footerSubmitForm: {
    padding: '20px 0 0'
  }
};

export default Newsletter;
