import { palette } from '../palette/Palette';

const VideoSectionSkeleton = {
  category: {
    backgroundColor: palette.primary.first
  },

  lightGreyBox: {
    backgroundColor: palette.grey.fourth
  },

  middleGreyBox: {
    backgroundColor: palette.grey.first
  },

  darkGreyBox: {
    backgroundColor: palette.grey.fifth
  }
};

export default VideoSectionSkeleton;
