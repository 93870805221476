import { palette } from '../palette/Palette';

const UniversalVotingPopup = {
  popupInner: {
    background: palette.default.white
  },

  buttonWrapper: {
    borderTop: `1px solid ${palette.grey.first}`
  },

  button: {
    color: palette.primary.first,
    cursor: 'pointer'
  },

  alreadyVotedBtnOk: {
    color: palette.primary.first,
    textTransform: 'capitalize'
  },

  alreadyVotedTextWrapper: {
    '& p': {
      color: palette.grey.second
    }
  },

  horizontalLine: {
    borderBottomColor: palette.grey.ninth
  },

  titleMaxVoted: {
    padding: '0 81px'
  },

  textMaxVoted: {
    paddingLeft: 55,
    paddingRight: 55
  },

  horizontalLineSm: {
    marginBottom: 34
  },

  buttonMaxVotedSm: {
    marginBottom: 31
  },

  buttonMaxVoted: {
    textTransform: 'uppercase',
    color: palette.default.white
  },

  titleMaxVotedSm: {
    padding: '0 12px'
  },

  textMaxVotedSm: {
    paddingLeft: 12,
    paddingRight: 12
  }
};

export default UniversalVotingPopup;
