import { palette } from '../palette/Palette';

const FAQComponent = {
  answer: {
    color: palette.primary.first
  },

  title: {
    '& $faqTitle': {
      color: palette.primary.second
    }
  },

  accordion: {
    padding: '21px 0 0 0 '
  },

  icon: {
    width: 31,
    height: 31,
    right: '40px',
    '& path': {
      fill: palette.primary.first
    }
  },

  iconSm: {
    width: 24,
    height: 24,
    right: '20px'
  },

  iconOpen: {
    width: 32,
    height: 32,
    right: '40px'
  },

  iconOpenSm: {
    width: 24,
    height: 24,
    right: '20px'
  },

  accordionTitle: {
    color: palette.primary.first,
    padding: '30px 80px 30px 40px',
    '&:hover': {
      '& $icon': {
        '& path': {
          fill: palette.primary.second
        }
      }
    }
  },

  accordionTitleSm: {
    padding: '30px 80px 30px 20px'
  },

  accordionBody: {
    padding: '0px 40px 58px'
  },

  accordionBodyMd: {
    padding: '0px 40px 40px'
  },

  accordionBodySm: {
    padding: '0px 20px 30px'
  },

  accordionItem: {
    position: 'relative',

    '&:nth-child(even)': {
      backgroundColor: palette.grey.first
    }
  },

  accordionItemExpanded: {
    marginBottom: 10,
    borderColor: palette.grey.first,
    paddingTop: 13
  },

  accordionItemExpandedSm: {
    paddingTop: 0
  }
};

export default FAQComponent;
