import { palette } from '@theme/palette/Palette';

const Quote = {
  quoteWrapper: {
    paddingLeft: 80,
    paddingRight: '10%'
  },

  quoteWrapperMd: {
    paddingRight: '25%'
  },

  quoteIconStyle: {
    fill: palette.primary.first,
    position: 'absolute',
    left: 0
  },

  quoteIconStyleSm: {
    position: 'relative',
    paddingBottom: 20
  },

  quoteIcon: {
    width: 60,
    height: 60
  },

  quoteIconSm: {
    width: 40,
    height: 40
  },
  quoteText: {
    color: palette.primary.second
  }
};

export default Quote;
