import { palette } from '../palette/Palette';

const ArticleSection = {
  header: {
    marginBottom: 39
  },

  articleSectionTitle: {
    color: palette.primary.second
  },

  link: {
    '&:hover': {
      '& p': {
        color: palette.primary.second
      },
      '& path': {
        fill: palette.primary.second
      }
    }
  },

  linkTextWrapper: {
    alignSelf: 'center'
  },

  linkText: {
    color: palette.primary.first,
    whiteSpace: 'nowrap'
  },

  arrowIcon: {
    paddingBottom: 2,
    height: 14,
    width: 14,
    marginLeft: 6,

    '& path': {
      fill: palette.primary.first
    }
  },

  arrowIconMd: {
    height: 12,
    width: 12
  }
};

export default ArticleSection;
