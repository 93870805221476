import { palette } from '../palette/Palette';

const VideoCard = {
  textHolder: {
    color: palette.grey.second,
    '&:hover': {
      cursor: 'pointer'
    }
  },

  textHolderCover: {
    color: palette.primary.third
  },

  iconPlayHover: {
    '& g circle': {
      fill: palette.primary.second
    }
  }
};

export default VideoCard;
