import React from 'react';
import SvgIcon from '@talpanetwork/talpa-publishing-core/SvgIcon';

const overlay = (props) => (
  <SvgIcon {...props} width={121} height={240} viewBox="0 0 121 240">
    <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#000"
            d="M120 0v100.572C118.413 65.793 112.58 32.105 103 0v240H0V0h120zm0 139.428V240h-17c9.579-32.105 15.412-65.793 17-100.572zM0 0h103v240H0V0z"
            transform="translate(-3946.000000, -1981.000000) translate(3946.904382, 1981.000000) translate(60.000374, 120.000000) scale(-1, 1) translate(-60.000374, -120.000000)"
          />
          <path
            d="M0.096 0H120.096V240H0.096z"
            transform="translate(-3946.000000, -1981.000000) translate(3946.904382, 1981.000000)"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

overlay.propTypes = SvgIcon.propTypes;

overlay.defaultProps = SvgIcon.defaultProps;

const SvgSliderHeaderCurve = {
  overlay
};

export default SvgSliderHeaderCurve;
