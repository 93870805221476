import { palette } from '../palette/Palette';

const JackpotCounter = {
  titleJackpot: {
    padding: '30px 0 18px'
  },

  jackpotNumWrapper: {
    backgroundColor: palette.primary.first
  },

  titleJackpotMd: {
    padding: '30px 0 23px'
  },

  titleJackpotSm: {
    padding: '30px 0 6px'
  },

  jackpotNum: {
    padding: '32px 0'
  },

  jackpotNumSm: {
    padding: '23px 0'
  }
};

export default JackpotCounter;
