const Scrubber = {
  startTime: {
    color: '#706f6f',
    fontFamily: 'Sky-Bold'
  },
  endTime: {
    color: '#706f6f',
    fontFamily: 'Sky-Bold'
  }
};

export default Scrubber;
