const ProgramSection = {
  outerContainer: {
    week: {
      padding: '0px'
    },
    day: {
      margin: '0',
      paddingRight: '20px',
      textTransform: 'capitalize',
      transition: 'color 0.15s ease-in-out'
    }
  },
  outerContainerSm: {
    week: {
      width: '115%',
      left: '-6%'
    },
    day: {
      paddingRight: '26px'
    }
  },
  outerContainerMd: {
    day: {
      paddingRight: '15px'
    }
  },
  container: {
    padding: '0 0 0 60px'
  },
  containerMd: {
    padding: '0px',
    width: '115%',
    left: '-5%'
  },
  innerContainer: {
    padding: '23px 0px 40px 40px'
  },
  innerContainerMd: {
    padding: '23px 0px 0'
  },
  innerContainerSm: {
    padding: '29px 0px 0'
  },
  sectionTitle: {
    textTransform: 'uppercase'
  },
  title: {
    margin: '0 0 24px',
    textTransform: 'uppercase'
  },
  titleMd: {
    textTransform: 'capitalize'
  },
  titleSm: {
    margin: '0 0 28px'
  },
  inactive: {
    color: '#0091ff'
  },
  active: {
    color: '#1e3246'
  },
  week: {
    padding: 0,
    marginBottom: 20
  },
  day: {
    margin: '0',
    paddingRight: '20px',
    textTransform: 'capitalize',
    transition: 'color 0.15s ease-in-out'
  },
  weekSm: {
    width: '100%'
  },
  daySm: {
    paddingRight: '26px'
  },

  dayMd: {
    paddingRight: '15px'
  },

  containerSm: {
    height: 170,
    left: '-5%'
  },
  image: {
    top: '50%',
    left: '30%',
    display: 'block',
    transform: 'translate(-30%, -50%)',
    height: 'auto',
    width: '100%'
  },
  imageMd: {
    maxHeight: 200
  },
  imageSm: {
    maxHeight: 172
  }
};

export default ProgramSection;
