import { palette } from '../palette/Palette';

const SocialShare = {
  socialShare: {
    marginTop: 32
  },

  socialShareMd: {
    marginTop: 24
  },

  socialShareSm: {
    marginTop: 20
  },

  socialShareInnerBackground: {
    backgroundColor: palette.primary.first,
    borderRadius: 2,

    '& $shareIcon': {
      fill: palette.default.white
    },

    '&:hover': {
      backgroundColor: palette.primary.third
    }
  },

  svgIcons: {
    width: 20,
    height: 20
  }
};

export default SocialShare;
